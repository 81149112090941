import React, { useEffect, useState } from 'react'
import Table from 'react-table'
import Typography from '@material-ui/core/Typography'
import ModalButtonWrap from '../modal/modalButton'
import LoadingOverlay from 'react-loading-overlay'
import { fetchWaitingLists, acceptUser } from '../../store/actions/waitinglist'
import constants from '../../constants'

import {
  QuotsButton,
  QuotsActionButton,
  BaseList,
  BaseFormErrors,
  CancelButton
} from '../styled'
import { useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'

LoadingOverlay.propTypes = undefined  
const { grey, darkGrey } = constants.colors

const TrStyles = () => ({
  style: {
    borderBottom: `2px solid ${darkGrey}`,
    backgroundColor: grey
  }})

const TheadThProps = () => ({
  style: {
    border: 'none'
  }})

const TdProps = () => ({
  style: {
    borderLeft: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }})

const TrGroupProps = () => ({
  style: {
    borderBottom: 'none'
  }})

const TbodyProps = () => ({
  style: {
    maxHeight: '16rem'
  }})

function WaitingLists({ open, lists, error, loading }) {
  const [showList, setShowList] = useState(false);
  const [list, setList] = useState(undefined);
  const dispatch = useDispatch();
  const openList = (list) => {
    setList(list)
    setShowList(true);
  }
  useEffect(() => {
    if (open) {
      setShowList(false);
    }
  }, [open]);
  useEffect(() => {
    if (showList) {
      const currList = find(lists, l => l.optionId === list?.optionId)
      if (currList) {
        setList(currList)
        setShowList(true);
      }
    }
  }, [lists])
  const acceptUserAction = (optionId, userId) => {
    dispatch(acceptUser(constants.session.getId(), optionId, userId));
  }

  const lists_columns = [
    {
      Header: 'Nom liste d\'attente',
      accessor: 'questionValue',
      resizable: false,
      sortable: false
    },
    {
      Header: 'Nombre de places disponibles',
      accessor: 'rest',
      resizable: false,
      sortable: false
    },
    {
      Header: 'Nombre de personnes dans la liste d\'attente',
      accessor: 'queued',
      resizable: false,
      sortable: false
    },
    {
      Header: '',
      accessor: 'questionId',
      resizable: false,
      sortable: false,
      Cell: ({ original }) => (
        <QuotsActionButton onClick={ () => openList(original) }>
          Gérer
        </QuotsActionButton>
      ),
      maxWidth: 100
    },
  ];

  const list_columns = list => ([
    {
      Header: 'Position',
      accessor: 'position in queue',
      resizable: false,
      sortable: false,
      Cell: ({ index }) => <span>{ index + 1}</span>,
    },
    {
      Header: 'Email',
      accessor: 'e-mail',
      resizable: false,
      sortable: false
    },
    {
      Header: 'Prénom',
      accessor: 'first name',
      resizable: false,
      sortable: false
    },
    {
      Header: 'Nom',
      accessor: 'last name',
      resizable: false,
      sortable: false
    },
    {
      Header: 'Accepter cet utilisateur ?',
      resizable: false,
      sortable: false,
      Cell: ({ original }) => (
        <QuotsActionButton 
          onClick={ () => { acceptUserAction(original['optionId'], original['userId']) } } 
          disabled={ list.rest <= 0 }
        >
          Accepter
        </QuotsActionButton>
      ),
      maxWidth: 180,
      headerStyle: { color: 'green'}
    },
  ]);

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="loaging..."
      styles={{ wrapper: { height: '100%' } }}
    >
      { !showList ? 
      <BaseList>
        <Typography variant="h6" gutterBottom>
          Gérer mes listes d'attentes
        </Typography>
        <Table
          columns={lists_columns}
          data={lists.filter(wl => wl.waitlistAllowed === 'Yes')}
          showPagination={false}
          defaultPageSize={5}
          style={{ width: 'calc(100% - 1px)', fontFamily: 'roboto' }}
          className={'waiting-lists'}
          getTheadProps={TrStyles}
          getTheadThProps={TheadThProps}
          getTdProps={TdProps}
          getTrGroupProps={TrGroupProps}
          getTbodyProps={TbodyProps}
          manual
        />
        <BaseFormErrors error={error}>{error}</BaseFormErrors>
      </BaseList>
      : 
      <BaseList>
        <Typography variant="h6" gutterBottom>
          Gérer liste d'attente : { list.questionValue }
        </Typography>
        <Typography variant="caption" gutterBottom>
          Il y a <b>{ list.rest }</b> places disponibles pour <b>{ list.queued }</b> personnes.
        </Typography>
        <Table
          columns={list_columns(list)}
          data={list.users}
          showPagination={false}
          defaultPageSize={-1}
          style={{ width: 'calc(100% - 1px)', fontFamily: 'roboto' }}
          className={'waiting-list'}
          getTheadProps={TrStyles}
          getTheadThProps={TheadThProps}
          getTdProps={TdProps}
          getTrGroupProps={TrGroupProps}
          getTbodyProps={TbodyProps}
          manual
        />
        <BaseFormErrors error={error}>{error}</BaseFormErrors>
        <CancelButton onClick={ () => { setShowList(false)} } style={{ alignSelf: 'flex-end'}}>
          Fermer
        </CancelButton>
      </BaseList>
      }
    </LoadingOverlay>
  )
}

export default function() {
  const [open, SetOpen] = useState(false)
  const lists = useSelector(state => state.waitinglist.lists)
  const error = useSelector(state => state.waitinglist.error)
  const loading = useSelector(state => state.waitinglist.loading)
  const dispatch = useDispatch();
  useEffect(() => { 
    if (open) {
      dispatch(fetchWaitingLists(constants.session.getId()))
    }
  }, [open]);
  return (
    <ModalButtonWrap
      open={open}
      right={true }
      onBlur={() => SetOpen(false)}
      content={ <WaitingLists open={open} lists={lists} error={error} loading={loading} /> }
    >
      <QuotsButton onClick={ () => {SetOpen(true); } }>
        LISTES D'ATTENTES
      </QuotsButton>
    </ModalButtonWrap>
  )
}
