import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'

import { InputTypes } from '../inputs/inputTypes'
import { setSearchQuery } from '../../store/actions/users'
import AdvancedSearchForm from './advancedSearch'
import Autocomplete from './autocomplete'
import ModalButtonWrap from '../modal/modalButton'
import SimpleSearch from './simpleSearch'

import {
  SearchButton,
  SearchInputWrapper,
  SearchWrapper,
  AdvancedSearchActive,
  SearchDrawer,
  AdvancedSearch
} from '../styled'
import { useDispatch, useSelector } from 'react-redux'

export const SearchableInputs = [
  InputTypes.email,
  InputTypes.multipleSelect,
  InputTypes.select,
  InputTypes.radio,
  InputTypes.text,
  InputTypes.textarea
]

export default function(props) {
  const advancedFilters = useSelector(state => state.users.advancedFilters)
  const [search, setSearch] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)
  const [openAdvanced, setOpenAdvanced] = useState(false)
  const [advancedSearchActive, setAdvancedSearchActive] = useState(false)
  const dispatch = useDispatch()
  const onSearch = () => {
    dispatch(setSearchQuery(search))
  }
  const advancedSearchId = 'toggle-advanced-search'
  const showAdvancedSearch = () => {
    setOpenAdvanced(true)
  }
  const hideAdvancedSearch = () => {
    setShowDrawer(false)
    setOpenAdvanced(false)
  }

  useEffect(() => {
    setAdvancedSearchActive(advancedFilters.length > 0)
  }, [advancedFilters])
  const onBlur = event => {
    event.persist()
    const relatedTarget = event.relatedTarget || { id: '' }
    /* blur event will override child click event, so we have to call it manually */
    if (relatedTarget.id === advancedSearchId) {
      relatedTarget.click()
    }
    setShowDrawer(false)
  }
  return (
    <SearchWrapper onBlur={onBlur}>
      <ModalButtonWrap
        right={true}
        open={openAdvanced}
        content={
          <AdvancedSearchForm
            onBlur={hideAdvancedSearch}
            opened={openAdvanced}
          />
        }
        onBlur={hideAdvancedSearch}
      >
        <SearchInputWrapper>
          {advancedSearchActive ? (
            <AdvancedSearchActive
              onMouseDown={showAdvancedSearch}
              showDrawer={showDrawer}
            >
              Recherche avancée active
            </AdvancedSearchActive>
          ) : (
            <SimpleSearch
              search={search}
              setShowDrawer={setShowDrawer}
              onSearch={onSearch}
              setSearch={setSearch}
              showDrawer={showDrawer}
            />
          )}
          {showDrawer ? (
            <SearchDrawer>
              <Autocomplete search={search} setSearch={setSearch} />
              <AdvancedSearch
                id={advancedSearchId}
                onClick={showAdvancedSearch}
              >
                Utiliser la recherche avancée
              </AdvancedSearch>
            </SearchDrawer>
          ) : null}
        </SearchInputWrapper>
      </ModalButtonWrap>
      <SearchButton onClick={onSearch}>
        <SearchIcon />
      </SearchButton>
    </SearchWrapper>
  )
}
