import types from '../types'

const defultSession = {
  id: '',
  name: '',
  date: ''
}

const sessionFromLivee = session => {
  return {
    id: session.id,
    name: session.name,
    date: session.date
  }
}

const initialState = {
  session: defultSession,
  loading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SESSION_REQUEST:
      return { ...state, error: null, loading: true }

    case types.FETCH_SESSION_ERROR:
      return {
        ...state,
        session: defultSession, 
        error: action.payload, 
        loading: false 
      }

    case types.FETCH_SESSION_SUCCESS: {
      return {
        ...state,
        session: sessionFromLivee(action.payload),
        loading: false,
        error: null
      }
    }      
    default:
      return state
  }
}
