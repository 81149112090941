import React from 'react'
import { SessionHeader, SessionLogo, SessionDetails, SessionError } from '../styled'
import { useSelector } from 'react-redux'

export default function() {
    const id = useSelector(state => state.sessions.session.id)
    const name = useSelector(state => state.sessions.session.name)
    const date = useSelector(state => state.sessions.session.date)
    const error = useSelector(state => state.sessions.error)
    const users = useSelector(state => state.users.users.length)
    const customer = useSelector(state => state.auth.user.customer)
    const dt = new Date(date).toLocaleDateString();
  return (
    <SessionHeader>
        <SessionLogo>
            <img src="/livee-purple.png" width="100px" alt=''/>
        </SessionLogo>
        { (!error) ? 
        <div>
            <SessionDetails>
                <strong>Connecté au compte: </strong> {customer} | {id} <strong>Session: </strong>{name} du {dt} 
            </SessionDetails>
            <SessionDetails>
                <strong>Nombre total d'inscrits </strong> {users}
            </SessionDetails>
        </div>
        : 
        <SessionError>Failed to load session information.</SessionError>
        }

    </SessionHeader>
  )
}
