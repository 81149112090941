import React from 'react'
import Typography from '@material-ui/core/Typography'
import { GuessInputType } from '../inputs'
import {
  BaseFieldLabel,
  BaseFieldInput,
  BaseFormErrors,
  Compulsory,
  UserFormField,
} from '../styled'

export const UserForm = ({
  fields,
  user,
  error,
  loading,
  onUpdateUserField,
}) => {
  const updateUserField = (field, event) => {
    const value =
      field.field_type === 'checkbox'
        ? event.target.checked
          ? 'Y'
          : 'N'
        : event.target.value
    onUpdateUserField(field.name, value)
  }
  const isNew = !user.id
  return (
    <>
      {fields.map((field) => (
        <UserFormField key={field.name}>
          <BaseFieldLabel>
            <Typography variant="body1">
              {field.label} {field.required && <Compulsory>*</Compulsory>} :
            </Typography>
          </BaseFieldLabel>
          <BaseFieldInput>
            <GuessInputType
              editable={!loading && (field.name !== 'Email' || isNew)}
              onChange={(event) => updateUserField(field, event)}
              type={field.field_type}
              values={field.values || []}
              value={user.values[field.name] || ''}
            />
          </BaseFieldInput>
        </UserFormField>
      ))}
      <BaseFormErrors error={!!error}>{error}</BaseFormErrors>
    </>
  )
}
