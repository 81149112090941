import React, { useState } from 'react'
import AccessTimeIcon from '@material-ui/icons/AccessTimeRounded'
import { Checkbox, IconButton, Popover } from '@material-ui/core'

export const WaitingListLabel = ({
  label,
  rest,
  isWaitingListAllowed = false,
  isOptionOwner = false,
  inWaitingList = false,
  inWaitingListQueue = false,
  onToggleWaitingListQueue,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <span>{label}</span>
      {typeof rest === 'number' && (
        <span
          style={{
            color: 'red',
            ...((!(rest !== 0 || isOptionOwner) || inWaitingList) && { opacity: '0.5' }),
          }}
        >
          {' '}
          (reste: {rest})
        </span>
      )}
      {inWaitingList && (
        <strong style={{ color: 'red' }}> (On waiting list)</strong>
      )}
      {(!inWaitingList && rest === 0 && !isOptionOwner && isWaitingListAllowed) && (
        <>
          <IconButton onClick={handleClick}>
            <AccessTimeIcon
              key={
                inWaitingListQueue
                  ? 'inWaitingListQueue'
                  : 'notInWaitingListQueue'
              }
              style={{
                color: 'red',
                backgroundColor: inWaitingListQueue ? 'lightcoral' : 'auto',
              }}
            />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <div style={{ padding: '10px' }}>
              <div>
                Ce participant sera en file d’attente pour cette option
                <Checkbox
                  checked={inWaitingListQueue}
                  onChange={onToggleWaitingListQueue}
                />
              </div>
            </div>
          </Popover>
        </>
      )}
    </>
  )
}
