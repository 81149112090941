import styled from 'styled-components'

import constants from '../../constants'

export const HEADER_HEIGHT = '60px'

const {
  primary,
  white,
  grey,
  lightGrey,
  darkBlue,
  darkRed,
  red,
  black,
  greySecond,
  opacityGrey,
  greyBlack
} = constants.colors

export const StyledHeader = styled.div`
  height: 60px;
  display: flex;
  width: 100%;
  font-family: roboto;
  font-weight: 400;
  align-items: center;
  justify-content: center;
`

export const StyledEyeIcon = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TableSizeText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: roboto;
  height: 100%;
  margin: 0px 10px;
`

export const TopBar = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT};
  color: ${white};
  display: flex;
  background-color: ${primary};
  justify-content: space-between;
`

export const TopBarLeft = styled.div`
  display: flex;
  margin-left: 15px;
  align-items: center;
`

export const TopBarRight = styled.div`
  display: flex;
  margin-right: 15px;
  align-items: center;
`

export const BaseForm = styled.div`
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 40px 20px 40px;
  flex-direction: column;
`

export const BaseList = styled.div`
  width: 850px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 40px 20px 40px;
  flex-direction: column;
`

export const FileInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const FileName = styled.div`
  color: ${lightGrey};
  font-style: italic;
`

export const ImportActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`

export const ExportActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`

export const UserFormField = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: 10px 0px;
`

export const BaseFieldInput = styled.div`
  min-width: 50%;
  max-width: 100%;
`

export const BaseFormActions = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const BaseFormErrors = styled.div`
  width: 100%;
  display: ${props => (props.error === false ? 'none' : 'block')};
  height: 60px;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${darkRed};
`

export const AddUserField = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: 10px 0px;
`

export const SearchInputWrapper = styled.div`
  padding: 4px 20px;
  width: 85%;
  position: relative;
`

export const SearchWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const AdvancedSearchActive = styled.div`
  background-color: ${white};
  width: 100%;
  border-radius: ${props =>
    props.showdrawer === 'true' ? '4px 4px 0px 0px' : '4px'};
  border: 1px solid ${grey};
  cursor: pointer;
  color: ${lightGrey};
  font-family: Roboto;
  font-size: small;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
`

export const SearchDrawer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${white};
  width: calc(100% - 40px);
  max-height: 50vh;
  overflow: auto;
  margin: 0px 20px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid ${grey};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  left: 0;
`

export const Autocomplete = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const SearchElement = styled.div`
  color: ${black};
  border-bottom: 1px solid ${greySecond};
  width: calc(90% - 10px);
  font-family: Roboto;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: ${white};
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${grey};
  }
`

export const BaseField = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: 10px 0px;
`

export const ModalButtonWrap = styled.div`
  position: relative;
`

export const Backdrop = styled.div`
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${opacityGrey};
`

export const Modal = styled.div`
  position: absolute;
  z-index: 2;
  ${props => (props.right ? 'right: 10%;' : null)}
`

export const Triangle = styled.div`
  width: 0;
  height: 0;
  margin-left: 40px;
  margin-right: 20px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent ${white} transparent;
`

export const ModalContentWrapper = styled.div`
  background-color: ${white};
  ${props => (props.right ? 'right: 0;position: absolute;' : null)}
  border-radius: 3px;
  color: ${greyBlack};
`

export const CellWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`

export const HighlightWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: roboto;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`

export const InputWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CustomCellWrapper = styled.div`
  width: 100%;
`

export const AuthGuardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const AuthError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: bold;
`

export const AuthErrorMessage = styled.div`
  margin-top: 20px;
  color: ${red};
`

export const AuthPending = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: bold;
`

export const AuthPendingMessage = styled.div`
  margin-top: 20px;
  color: ${darkBlue};
`

export const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`

export const ListWrapper = styled.div`
  width: 100%;
  height: calc(100% - ${HEADER_HEIGHT});
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`

export const SessionHeader = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin: 5px;
  font-family: roboto;
`

export const SessionLogo = styled.div`
  padding: 10px;
`

export const SessionDetails = styled.div`
  margin: 5px;
`

export const SessionError = styled.div`
  margin: 4px;
  color: tomato;
  font-weight: bolder;
`