import types from '../types'

const initialState = {
  authenticated: false,
  error: null,
  user: null,
  loading: false,
  token: null
}

export default (state = initialState, action) => {
  switch (action.type) {
  case types.AUTHENTICATION_REQUEST:
    return { ...state, loading: true }

  case types.AUTHENTICATION_ERROR:
    return {
      ...state,
      loading: false,
      error: action.payload,
      authenticated: false
    }

  case types.AUTHENTICATION_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null,
      token: action.payload.token,
      authenticated: true,
      user: action.payload.user
    }

  default:
    return state
  }
}
