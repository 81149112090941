import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import styled from 'styled-components'

import constants from '../../constants'

const {
  primary,
  white,
  grey,
  lightBlue,
  orange,
  disabled,
  darkBlue,
  darkGrey,
  red,
  green,
  blue,
  black
} = constants.colors

export const FlatButton = styled(ButtonBase)`
  padding: 0px 20px;
  background-color: ${white};
  color: ${black};
  border-radius: 3px;
  border: 0;
  height: 40px;
`

export const ActionButton = styled(ButtonBase)`
  background-color: ${white};
  color: ${black};
  border: 0;
  height: 40px;
`

export const BlueButton = styled(FlatButton)`
  background-color: ${props => (props.disabled ? grey : lightBlue)};
  color: ${white};
  margin-left: 10px;
`

export const SaveButton = styled(FlatButton)`
  background-color: ${orange};
  color: ${white};
  margin-left: 10px;
`

export const ExportButton = styled(FlatButton)`
  background-color: ${disabled};
  color: ${white};
  margin-left: 10px;
`

export const EditButton = styled(FlatButton)`
  background-color: ${props => (props.disabled ? disabled : darkBlue)};
  color: ${white};
  margin-left: 10px;
`

export const DelButton = styled(FlatButton)`
  background-color: ${props => (props.disabled ? disabled : red)};
  color: ${white};
  margin-left: 10px;
`

export const AddButton = styled(FlatButton)`
  background-color: ${green};
  color: ${white};
`

export const QuotsButton = styled(FlatButton)`
  background-color: ${white};
  color: ${red};
  border: 2px solid ${red};
  margin-left: 10px;
`

export const QuotsActionButton = styled(FlatButton)`
  background-color: ${props => (props.disabled ? darkGrey : green)};
  color: ${white};
`

export const SearchButton = styled(FlatButton)`
  background-color: ${blue};
  color: ${white};
  height: 38px;
`

export const AdvancedSearch = styled(Button)`
  color: ${primary};
  width: 100%;
  font-size: x-small;
  padding: 10px 0px;
  font-style: italic;
`

export const AdvancedSearchButton = styled(FlatButton)`
  background-color: ${blue};
  color: ${white};
`

export const RemoveSearchButton = styled(FlatButton)`
  background-color: ${red};
  color: ${white};
  margin-right: 10px;
`

export const ConfirmButton = styled(FlatButton)`
  background-color: ${green};
  color: ${white};
`

export const CancelButton = styled(FlatButton)`
  background-color: ${red};
  color: ${white};
`
