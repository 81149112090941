import BuildIcon from '@material-ui/icons/Build'
import DeleteIcon from '@material-ui/icons/Delete'
import styled from 'styled-components'

import constants from '../../constants'

const { red, blue, highlight } = constants.colors

export const Highlight = styled.mark`
  background-color: ${highlight};
`

export const EditImageIcon = styled(BuildIcon)`
  position: absolute;
  margin: 0px 0px 0px 40px;
  top: 5px;
  left: 50%;
  color: ${blue};
  cursor: pointer;
`

export const DeleteImageIcon = styled(DeleteIcon)`
  position: absolute;
  margin: 0px 0px 0px 40px;
  bottom: 5px;
  left: 50%;

  color: ${red};
  cursor: pointer;
`
