import { StylesProvider } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import LoadingOverlay from 'react-loading-overlay'

import { AppWrapper, Notification, ListWrapper } from './components/styled'
import { fetchUsers } from './store/actions/users'
import { fetchSession } from './store/actions/sessions'
import AuthGuard from './components/auth'
import constants from './constants'
import TopBarComponent from './components/topbar'
import UsersListDisplay from './components/users/list'
import SessionComponent from './components/session'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWaitingLists } from './store/actions/waitinglist'

LoadingOverlay.propTypes = undefined

export default function(props) {
  const filteredUsers = useSelector(state => state.users.filteredUsers)
  const fields = useSelector(state => state.users.fields)
  const filteredFields = useSelector(state => state.users.filteredFields)
  const authenticated = useSelector(state => state.auth.authenticated)
  const loading = useSelector(state => state.users.loading)
  const notification = useSelector(state => state.notifs.notification)
  const dispatch = useDispatch()
  useEffect(() => {
    if (authenticated) {
      dispatch(fetchUsers(constants.session.getId()));
      dispatch(fetchSession(constants.session.getId()));
      dispatch(fetchWaitingLists(constants.session.getId()));
    }
  }, [dispatch, authenticated, props])

  const [displayedNotif, setDisplayedNotif] = useState({
    type: '',
    message: ''
  })
  const [showNotif, setShowNotif] = useState(false)

  useEffect(() => {
    if (notification !== null) {
      setShowNotif(false)
      setDisplayedNotif(notification)
      setShowNotif(true)
    }
  }, [notification])

  // useInterval(() => {
  //   if (authenticated) {
  //     let dt = Date.now()
  //     dispatch(reloadUsers(constants.session.getId(), dt))
  //   }
  // }, 6000)

  return (
    <StylesProvider injectFirst>
      <AuthGuard>
        <AppWrapper>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={showNotif}
            autoHideDuration={6000}
            onClose={() => setShowNotif(false)}
          >
            <Notification
              type={displayedNotif.type}
              message={displayedNotif.message}
            />
          </Snackbar>
          {loading && fields.lengths > 0 ? (
            <CircularProgress />
          ) : (
            <>
              <SessionComponent />
              <TopBarComponent />
              <LoadingOverlay
                active={loading}
                spinner
                text="Loading data..."
                styles={{ wrapper: { height: '100%', width: '100%' } }}
              >
                <ListWrapper>
                  <UsersListDisplay
                    fields={fields}
                    filteredFields={filteredFields}
                    users={filteredUsers}
                  />
                </ListWrapper>
              </LoadingOverlay>
            </>
          )}
        </AppWrapper>
      </AuthGuard>
    </StylesProvider>
  )
}
