import ErrorIcon from '@material-ui/icons/ReportProblem'
import Loader from '@material-ui/core/CircularProgress'
import React, { useEffect } from 'react'

import { authenticate } from '../../store/actions/auth'

import {
  AuthGuardWrapper,
  AuthError,
  AuthErrorMessage,
  AuthPending,
  AuthPendingMessage
} from '../styled'
import { useDispatch, useSelector } from 'react-redux'

export default function AuthGuard({ children }) {
  const dispatch = useDispatch()
  const authenticated = useSelector(state => state.auth.authenticated)
  const loading = useSelector(state => state.auth.loading)

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const urlAutologin = params.get('autologin')
    const urlToken = params.get('token')
    if (urlAutologin) {
      if (authenticated === false && loading === false) {
        dispatch(authenticate({ token: urlToken }))
      }
    } else {
      console.error('This app curently only supports autologin mode')
    }
  }, [authenticated, dispatch, loading])
  return (
    <AuthGuardWrapper>
      {authenticated === false && loading ? (
        <AuthPending>
          <Loader />
          <AuthPendingMessage>Connexion en cours ...</AuthPendingMessage>
        </AuthPending>
      ) : authenticated === true ? (
        children
      ) : (
        <AuthError>
          <ErrorIcon />
          <AuthErrorMessage>
            Vous devez être connecté pour accéder à l'Event-Manager
          </AuthErrorMessage>
        </AuthError>
      )}
    </AuthGuardWrapper>
  )
}
