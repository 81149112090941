import { InputTypes } from '../components/inputs'

export function getColumnWidth(data, accessor, headerText, type) {
  if (typeof accessor === 'string' || accessor instanceof String) {
    accessor = d => d[accessor] // eslint-disable-line no-param-reassign
  }
  const maxWidth = 600
  const magicSpacing = 10
  const padding = 24
  const cellLength =
    type === InputTypes.image
      ? 20
      : Math.max(
        ...data.map(row => (`${accessor(row)}` || '').length),
        headerText.length
      )

  return Math.min(maxWidth, cellLength * magicSpacing + padding)
}
