import AuthService from '../../services/auth.service'
import ActionTypes from '../types'

const AuthenticateRequest = () => ({
  type: ActionTypes.AUTHENTICATION_REQUEST,
  payload: null
})

const AuthenticateError = error => ({
  type: ActionTypes.AUTHENTICATION_ERROR,
  payload: error
})

const AuthenticateSuccess = payload => ({
  type: ActionTypes.AUTHENTICATION_SUCCESS,
  payload
})

export const authenticate = credentials => dispatch => {
  dispatch(AuthenticateRequest())
  AuthService.autologin(credentials)
    .then(response => {
      localStorage.setItem('token', response.token)
      return dispatch(AuthenticateSuccess(response))
    })
    .catch(error => AuthenticateError(error))
}
