import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Icon from '@material-ui/core/Icon'
import React, { useState } from 'react'

import { BaseForm, SaveButton } from '../styled'
import { setFilteredFields } from '../../store/actions/users'
import ModalButtonWrap from '../modal/modalButton'
import { useDispatch, useSelector } from 'react-redux'

const FilterForm = () => {
  const fields = useSelector(state => state.users.fields)
  const filteredFields = useSelector(state => state.users.filteredFields)
  const dispatch = useDispatch()
  const onFieldChange = field => (ev, checked) => {
    if (checked) dispatch(setFilteredFields([...filteredFields, field]))
    else
      dispatch(
        setFilteredFields(filteredFields.filter(f => f.name !== field.name))
      )
  }
  return (
    <BaseForm>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Sélectionner les colonnes à afficher:
        </FormLabel>
        <FormGroup>
          {fields.map(field => (
            <FormControlLabel
              key={field.name}
              control={
                <Checkbox
                  checked={!!filteredFields.find(f => f.name === field.name)}
                  onChange={onFieldChange(field)}
                  value={field.name}
                />
              }
              label={field.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </BaseForm>
  )
}

export default function() {
  const [open, SetOpen] = useState(false)

  return (
    <ModalButtonWrap
      open={open}
      onBlur={() => SetOpen(false)}
      content={<FilterForm />}
      right
    >
      <SaveButton
        onClick={() => {
          SetOpen(true)
        }}
      >
        <Icon style={{ fontSize: '20px' }}>remove_red_eye</Icon>
      </SaveButton>
    </ModalButtonWrap>
  )
}
