import React from 'react'

import { DelButton } from '../styled'
import { confirmDelete, deleteUser } from '../../store/actions/users'
import ConfirmModal from '../modal/confirmModal'
import { useDispatch, useSelector } from 'react-redux'

export default function() {
  const selectedUsers = useSelector(state => state.users.selectedUsers)
  const openConfirmDelete = useSelector(state => state.users.confirmDelete)
  const dispatch = useDispatch()
  const setOpen = open => {
    dispatch(confirmDelete(open))
  }
  const deleteUsers = () => {
    selectedUsers.map(user => {
      dispatch(deleteUser(user));
      return setOpen(false);
    })
  }

  return (
    <ConfirmModal
      open={openConfirmDelete}
      onBlur={() => setOpen(false)}
      onConfirm={() => deleteUsers()}
      title={ selectedUsers.length > 1 ? 'Êtes-vous sûr de vouloir supprimer ces utilisateurs ?' : 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?'}
      content=""
    >
      <DelButton
        disabled={selectedUsers.length < 1}
        onClick={() => {
          setOpen(true)
        }}
      >
        SUPPRIMER
      </DelButton>
    </ConfirmModal>
  )
}
