import { useDebounce } from 'react-use'
import React, { useState } from 'react'
import { CellResolver } from '../inputs'
import { fetchUsers } from '../../store/actions/users'
import constants from '../../constants'
import { useDispatch } from 'react-redux'
import { UserContext } from '../../utils/userContext'
import { updateUser } from '../../store/actions/user'

export default (fields, cell) => {
  return row => {
    const dispatch = useDispatch()
    const [value, setValue] = useState(row.original.values[row.column.id])
    const onValueChange = event => {
      if (event.persist) event.persist()
      const eventValue = (cell.field_type === 'checkbox') ? (event.target.checked ? 'Y' : 'N') : event.target.value;
      setValue(eventValue)
      return eventValue
    }
    useDebounce(
      () => {
        const user = row.original
        const values = { ...user.values, [cell.name]: value }
        const updatedUser = { ...user, values: values }
        dispatch(
          updateUser(updatedUser)
        ).catch(e => {
          // no space to display error on inline edit, just reload users
          dispatch(fetchUsers(constants.session.getId()));
          console.error('Failed to update user: ', e);
        })
      },
      2000,
      [value]
    )
    return (
      <UserContext.Provider value={row.original}>
        <CellResolver
          value={value}
          cell={cell}
          onChange={onValueChange}
          values={cell.values}
        />
      </UserContext.Provider>
    )
  }
}
