import axios from 'axios'
import constants from '../constants'
import HttpService from './http.service'

const handleResponse = response => {
  return response.data;
}

class SessionsService extends HttpService {
  fetchSession(sessionId) {
    return axios
      .get(
        `${constants.API.SESSIONS_URL}/sessions/${sessionId}`,
        this.getOptions()
      )
      .then(handleResponse)
      .catch(this.onError)
  }
}

export default new SessionsService()
