import types from '../types'

const initialState = {
  notification: null
}

export default (state = initialState, action) => {
  switch (action.type) {
  case types.SET_NOTIFICATION:
    return { ...state, notification: action.payload }

  default:
    return state
  }
}
