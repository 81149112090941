import React from 'react'

import { SearchField } from '../styled'
import { useSelector } from 'react-redux'

export default function({
  showDrawer,
  search,
  setSearch,
  onSearch,
  setShowDrawer
}) {
  const loading = useSelector(state => state.users.loading)
  const EnterKey = 13
  const onKeyDown = event => {
    if (event.keyCode === EnterKey) {
      onSearch()
    }
  }
  return (
    <SearchField
      showdrawer={showDrawer.toString()}
      onKeyDown={onKeyDown}
      onClick={() => setShowDrawer(true)}
      label="Search"
      disabled={loading}
      value={search}
      onChange={event => setSearch(event.target.value)}
      type="search"
    />
  )
}
