import WaitingListService from '../../services/waitinglist.service'
import { fetchUsers } from './users'
import ActionTypes from '../types'
import { reduce } from 'lodash'

const FetchWaitingListsRequest = () => ({
  type: ActionTypes.FETCH_WAITINGLISTS_REQUEST,
  payload: null,
})

const FetchWaitingListsError = (error) => ({
  type: ActionTypes.FETCH_WAITINGLISTS_ERROR,
  payload: error,
})

const FetchWaitingListsSuccess = (payload) => ({
  type: ActionTypes.FETCH_WAITINGLISTS_SUCCESS,
  payload,
})

const SetWaitingListsCache = (payload) => ({
  type: ActionTypes.SET_WAITINGLISTS_CACHE,
  payload,
})

export const fetchWaitingLists = (session) => async (dispatch) => {
  dispatch(FetchWaitingListsRequest())
  // load all waiting lists at once, build cache with will be used in users list and edit form
  try {
    const wls = await WaitingListService.fetchWaitingLists(session)
    const optionIdTWL = reduce(
      wls,
      (acc, wl) => {
        return { ...acc, [wl.optionId]: wl }
      },
      {}
    )
    const all = await Promise.all(
      wls.map((wl) =>
        WaitingListService.fetchWaitingList(session, wl.optionId).then(
          (wl) => ({
            ...wl,
            questionDataAttrLabel:
              optionIdTWL[wl.optionId].questionDataAttrLabel,
            waitlistAllowed: optionIdTWL[wl.optionId].waitlistAllowed,
          })
        )
      )
    )
    const cache = reduce(
      all,
      (acc, wl) => {
        return {
          ...acc,
          [wl.optionId]: {
            waitlistAllowed: wl.waitlistAllowed,
            questionDataAttrLabel: wl.questionDataAttrLabel,
            rest: wl.rest,
            users: wl.users.map((u) => u.userId),
          },
        }
      },
      {}
    )
    dispatch(FetchWaitingListsSuccess(all))
    dispatch(SetWaitingListsCache(cache))
  } catch (error) {
    dispatch(FetchWaitingListsError(error))
  }
}

export const acceptUser = (session, optionId, userId) => (dispatch) => {
  WaitingListService.acceptUser(session, optionId, userId)
    .then(() => {
      dispatch(fetchWaitingLists(session))
      dispatch(fetchUsers(session))
    })
    .catch((error) => dispatch(FetchWaitingListsError(error)))
}
