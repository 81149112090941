export default {
  session: {
    getId() {
      return new URLSearchParams(window.location.search).get('session')
    }
  },
  language: 'french',
  API: {
    URL: process.env['REACT_APP_API_URL'] || 'http://localhost:3000',
    SESSIONS_URL: process.env['REACT_APP_API_SESSIONS_URL'] || 'http://localhost:3008',
    EXCEL_SERVICE:
      process.env['REACT_APP_EXCEL_URL'] || 'http://localhost:3002',
    AUTH:
      process.env['REACT_APP_AUTH_URL'] || 'http://localhost:3005/auth/special',
    PLATFORM_URL: 
      process.env['REACT_APP_PLATFORM_URL'] || 'https://staging.livee.com/app'
  },
  colors: {
    primary: '#9a12b3',
    white: '#fefefe',
    green: '#4caf50',
    red: '#ec4c4b',
    orange: '#FEAC47',
    disabled: '#620769',
    grey: '#eeeeee',
    blue: '#0077c2',
    darkBlue: '#007CD7',
    lightBlue: '#29b6f6',
    highlight: '#ffe082',
    success: '#4caf50',
    lightGrey: '#bdbdbd',
    darkRed: '#8b0000',
    black: '#000000',
    opacityGrey: 'rgba(0, 0, 0, 0.2)',
    greyBlack: '#212121',
    greySecond: '#e0e0e0',
    darkGrey: '#757575'
  }
}
