import auth from './auth'
import notifs from './notifs'
import users from './users'
import user from './user'
import waitinglist from './waitinglist'
import sessions from './sessions'

export default {
  ...auth,
  ...users,
  ...user,
  ...notifs,
  ...waitinglist,
  ...sessions,
}
