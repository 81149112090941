import types from '../types'

const defaultCache = {}

const initialState = {
  lists: [],
  cache: defaultCache,
  loading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_WAITINGLISTS_REQUEST:
      return { ...state, cache: defaultCache, error: null, loading: true }

    case types.FETCH_WAITINGLISTS_ERROR:
      return { ...state, error: action.payload, loading: false }

    case types.FETCH_WAITINGLISTS_SUCCESS: {
      return {
        ...state,
        lists: action.payload,
        loading: false
      }
    }
    case types.SET_WAITINGLISTS_CACHE: {
      return {
        ...state,
        cache: action.payload,
      }
    }
    default:
      return state
  }
}
