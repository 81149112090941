import { SearchableInputs } from '../components/search'
import { InputTypes, resolveValue } from '../components/inputs/inputTypes'
import { find, some, includes } from 'lodash'


export function getSearchIndex(value, query) {
  const textValue = (value || '').toLowerCase()
  const textQuery = (query || '').toLowerCase()
  return textValue.indexOf(textQuery)
}

export function simpleSearch(users, query, searchFields) {
  return users.filter(user => {
    return (
      query === '' ||
      !!searchFields
        .filter(field => SearchableInputs.indexOf(field.field_type) > -1)
        .find(field => {
          const value = resolveValue(field, user.values[field.name]);
          return getSearchIndex(value, query) > -1
        })
    )
  })
}

function getAdvancedSearchIndexAsText(value = '', field, advancedFilters) {
  const textValue = value.toLowerCase()
  const filter = advancedFilters.find(filter => {
    return filter.name === field.name
  }) || { value: '' }
  const filterValue = filter.value.toLowerCase()
  return textValue.indexOf(filterValue);
}

function getAdvancedSearchIndexAsSelect(value = '', field, advancedFilters) {
  if (value === null) value = '';
  const filter = advancedFilters.find(filter => {
    return filter.name === field.name
  }) || { value: '' }
  return value.indexOf(filter.value || '');
}

function getAdvancedSearchIndexAsMultiple(value = [], field, advancedFilters) {
  const mValue = value || [];
  const filter = advancedFilters.find(filter => {
    return filter.name === field.name
  }) || { value: [] }
  return some(mValue, v => includes(filter.value, v)) ? 0 : -1;
}

export function getAdvancedSearchIndex(value = '', field, advancedFilters) {
  switch(field.field_type) {
    case InputTypes.multipleSelect: return getAdvancedSearchIndexAsMultiple(value, field, advancedFilters)
    case InputTypes.select: 
    case InputTypes.radio: 
    {
      return getAdvancedSearchIndexAsSelect(value, field, advancedFilters)
    }
    default: return getAdvancedSearchIndexAsText(value, field, advancedFilters)
  }
}

export function advancedSearch(users, advancedFilters, searchFields) {
  return users.filter(user => {
    return searchFields
      .filter(field => includes(SearchableInputs, field.field_type))
      .filter(field => find(advancedFilters, af => af.name === field.name))
      .every(field => {
        const value = user.values[field.name];
        const index = getAdvancedSearchIndex(value, field, advancedFilters)
        return index > -1
      })
  })
}
