import { SearchableInputs } from '.'
import React, { useEffect, useState } from 'react'

import { Autocomplete, SearchElement } from '../styled'
import { getSearchIndex } from '../../utils/search'
import { find } from 'lodash'
import { resolveValue } from '../inputs'
import { useSelector } from 'react-redux'

export default function({ search, setSearch }) {
  const [suggestions, setSuggestions] = useState([])
  const [values, setValues] = useState([])
  const filteredUsers = useSelector(state => state.users.filteredUsers)
  const fields = useSelector(state => state.users.fields)

  useEffect(() => {
    const searchableFields = fields
      .filter(field => SearchableInputs.indexOf(field.field_type) > -1)
      .map(field => field.name)

    const searchableUsers = filteredUsers.map(user => {
      const searchableValues = Object.entries(user.values).filter(
        ([name]) => searchableFields.indexOf(name) > -1
      ).map(e => ([e[0], resolveValue(find(fields, f => f.name === e[0]), e[1])]));
      return { ...user, values: Object.fromEntries(searchableValues) }
    })
    const allValues = searchableUsers.reduce(
      (acc, { values }) => new Set([...acc, ...Object.values(values)]),
      new Set()
    )

    setValues(allValues)
  }, [fields, filteredUsers])

  useEffect(() => {
    const newSuggestions = Array.from(values).filter(
      value => search !== '' && getSearchIndex(value, search) > -1
    )
    setSuggestions(newSuggestions)
  }, [search, values])

  const onClick = newSearch => {
    setSearch(newSearch)
  }
  return (
    <Autocomplete>
      {suggestions.map((searchText, i) => (
        <SearchElement
          onMouseDown={() => onClick(searchText)}
          key={`sugg-${i}`}
        >
          {searchText}
        </SearchElement>
      ))}
    </Autocomplete>
  )
}
