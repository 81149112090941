import React from 'react'
import Typography from '@material-ui/core/Typography'
import ModalButtonWrap from '../modal/modalButton'
import { AddButton, BaseForm, BaseFormActions } from '../styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  createOpenAction,
  createUser,
  updateUserField,
} from '../../store/actions/user'
import { UserForm } from './UserForm'
import { UserContext } from '../../utils/userContext'

function AddUserForm({
  fields,
  user,
  onUpdateUserField,
  error,
  loading,
  onCreate,
}) {
  return (
    <BaseForm>
      <Typography variant="h6" gutterBottom>
        Ajouter un utilisateur :
      </Typography>
      <br />
      <UserContext.Provider value={user}>
        <UserForm
          fields={fields}
          user={user}
          onUpdateUserField={onUpdateUserField}
          error={error}
          loading={loading}
        />
      </UserContext.Provider>
      <BaseFormActions>
        <AddButton onClick={onCreate}>AJOUTER</AddButton>
      </BaseFormActions>
    </BaseForm>
  )
}

export default function () {
  const dispatch = useDispatch()
  const open = useSelector((state) => state.user.createOpen)
  const fields = useSelector((state) => state.users.fields)
  const user = useSelector((state) => state.user.user)
  const error = useSelector((state) => state.user.error)
  const loading = useSelector((state) => state.user.loading)
  const setOpen = (open) => {
    dispatch(createOpenAction(open))
  }
  const onUpdateUserField = (name, value) => {
    dispatch(updateUserField(name, value))
  }
  const onCreate = () => {
    dispatch(createUser(user))
  }
  return (
    <ModalButtonWrap
      open={open}
      onBlur={() => setOpen(false)}
      content={
        fields.length > 0 ? (
          <AddUserForm
            fields={fields}
            user={user}
            onUpdateUserField={onUpdateUserField}
            error={error}
            loading={loading}
            onCreate={onCreate}
          />
        ) : null
      }
    >
      <AddButton onClick={() => setOpen(true)}>
        AJOUTER UN UTILISATEUR
      </AddButton>
    </ModalButtonWrap>
  )
}
