import SessionsService from '../../services/sessions.service'
import ActionTypes from '../types'

const FetchSessionRequest = () => ({
  type: ActionTypes.FETCH_SESSION_REQUEST,
  payload: null
})

const FetchSessionError = error => ({
  type: ActionTypes.FETCH_SESSION_ERROR,
  payload: error
})

const FetchSessionSuccess = payload => ({
  type: ActionTypes.FETCH_SESSION_SUCCESS,
  payload
})

export const fetchSession = session => dispatch => {
  dispatch(FetchSessionRequest())
  SessionsService.fetchSession(session)
    .then(response => dispatch(FetchSessionSuccess(response)))
    .catch(error => dispatch(FetchSessionError(error)));
}
