import React, { useState } from 'react'
import ModalButtonWrap from '../modal/modalButton'
import constants from '../../constants'
import LoadingOverlay from 'react-loading-overlay'
import IframeResizer from 'iframe-resizer-react'
import { fetchUsers } from '../../store/actions/users'
import { AddButton } from '../styled'
import { useDispatch } from 'react-redux'
import { fetchWaitingLists } from '../../store/actions/waitinglist'

LoadingOverlay.propTypes = undefined

const Add = () => {
  const [loading, setLoading] = useState(false)
  const url = `${constants.API.PLATFORM_URL}/session_user_register.php?ChatSessionID=${constants.session.getId()}&LanguageID=1`
  return (
    <>
      <div style={{padding: '1.5rem'}}>
        <LoadingOverlay
          active={loading}
          spinner
          text="loaging..."
          styles={{ wrapper: { height: '100%' } }}
        >
          <IframeResizer
            src={url}
            style={{
              width: '800px',
              minWidth: '100%',
              height: '80vh',
              border: 'none',
            }}
            autoResize="true"
            onInit={() => {
              setLoading(false)
            }}
            scrolling="true"
          ></IframeResizer>
        </LoadingOverlay>
      </div>
    </>
  )
}

export default function () {
  const [open, SetOpen] = useState(false)
  const dispatch = useDispatch()
  return (
    <ModalButtonWrap
      open={open}
      onBlur={() => {
        dispatch(fetchWaitingLists(constants.session.getId()))
        dispatch(fetchUsers(constants.session.getId()))
        SetOpen(false)
      }}
      content={open ? <Add /> : null}
    >
      <AddButton onClick={() => SetOpen(true)}>
        AJOUTER UN UTILISATEUR
      </AddButton>
    </ModalButtonWrap>
  )
}
