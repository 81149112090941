import React from 'react'

import { getAdvancedSearchIndex, getSearchIndex } from '../../utils/search'
import { Highlight, HighlightWrapper }  from '../styled'
import { resolveValue } from './inputTypes'
import { useSelector } from 'react-redux'

export function getAdvancedHighlight(
  matchIndex,
  value,
  advancedFilters,
  field
) {
  const query = advancedFilters.find(filter => filter.name === field.name)
  if (query === undefined) {
    return {
      start: value,
      valueText: '',
      end: ''
    }
  }
  return getHighlight(matchIndex, value, query.value)
}

export function getHighlight(matchIndex, value, searchQuery) {
  const endIndex = matchIndex + searchQuery.length
  const valueText = value.slice(matchIndex, endIndex)
  const start = value.slice(0, matchIndex)
  const end = value.slice(endIndex)
  return {
    valueText,
    start,
    end
  }
}

export function HighlightText({ field, value = '' }) {
  if (value === null) value = '';
  value = resolveValue(field, value);
  const searchQuery = useSelector(state => state.users.searchQuery)
  const advancedFilters = useSelector(state => state.users.advancedFilters)
  const useAdvancedSearch = advancedFilters.length > 0
  const matchIndex = useAdvancedSearch
    ? getAdvancedSearchIndex(value, field, advancedFilters)
    : getSearchIndex(value, searchQuery)
  if (value.indexOf && matchIndex > -1) {
    const { valueText, start, end } = useAdvancedSearch
      ? getAdvancedHighlight(matchIndex, value, advancedFilters, field)
      : getHighlight(matchIndex, value, searchQuery)

    return (
      <HighlightWrapper>
        {start}
        <Highlight>{valueText}</Highlight>
        {end}
      </HighlightWrapper>
    )
  }
  return <HighlightWrapper>{value}</HighlightWrapper>
}
