import axios from 'axios'
import constants from '../constants'
import HttpService from './http.service'
import { filter, isNil } from 'lodash';

const handleResponse = response => {
  if (response.data.error) {
    return Promise.reject({ message: response.data.error.message });
  }
  return response.data;
}

const waitinglistsFromLivee = wls => {
  return filter(wls, (wl => typeof (+ wl['available']) === 'number' && wl['available'] !== 'unlimited')).map(wl => {
    const { questionName, questionValue, available, registered} = wl;
    const name = `${questionName} - ${questionValue}`;
    const rest = (available - registered) < 0 ? 0 : available - registered;
    return { ...wl, name, rest };
  });
}

const waitinglistFromLivee = wl => {
  const { optionId, available, registered } = wl;
  const rest = (available - registered) < 0 ? 0 : available - registered;
  const users = (wl.users || []).map(user => ({ ...user, optionId }));
  return { ...wl, rest, users };
}

class WaitingListService extends HttpService {
  fetchWaitingLists(sessionId) {
    return axios
      .get(
        `${constants.API.URL}/sessions/${sessionId}/waitinglist`,
        this.getOptions({ params: { lang: constants.language}})
      )
      .then(handleResponse)
      .then(waitinglistsFromLivee)
      .catch(this.onError)
  }

  async fetchWaitingList(sessionId, optionId) {
    return axios
      .get(
        `${constants.API.URL}/sessions/${sessionId}/waitinglist/${optionId}`,
        this.getOptions({ params: { lang: constants.language }})
      )
      .then(handleResponse)
      .then(waitinglistFromLivee)
      .catch(this.onError)
  }

  acceptUser(sessionId, optionId, userId) {
    return axios
      .put(
        `${constants.API.URL}/sessions/${sessionId}/waitinglist/accept`, {},
        this.getOptions({ params: { OptionValueID: optionId, UserID: userId, lang: constants.language }})
      )
      .then(handleResponse)
      .catch(this.onError)
  }

  queueUser(sessionId, optionId, userId) {
    return axios
      .put(
        `${constants.API.URL}/sessions/${sessionId}/waitinglist/add_user`, {},
        this.getOptions({ params: { OptionValueID: optionId, UserID: userId, lang: constants.language }})
      )
      .then(handleResponse)
      .catch(this.onError)
  }
}

export default new WaitingListService()
