import UserService from '../../services/user.service'
import WaitingListService from '../../services/waitinglist.service'
import ActionTypes from '../types'
import constants from '../../constants'
import { includes, find, each } from 'lodash'
import { createUserInt, updateUserInt } from './users'
import { fetchWaitingLists } from './waitinglist'

export const updateUserField = (name, value) => (dispatch, getState) => {
  const state = getState()
  const field = find(state.users.fields, (f) => f.name === name)
  if (value && field && field.field_type === 'radio') {
    each(field.values || [], (op) => {
      dispatch({
        type: ActionTypes.REMOVE_WAITING_LIST_QUEUE,
        payload: op.OptionValueID,
      })
    })
  }
  dispatch({ type: ActionTypes.UPDATE_USER_FIELD, payload: { name, value } })
}

export const toggleWaitingListQueue = (name, value) => (dispatch, getState) => {
  const state = getState()
  const inQueue = includes(state.user.wlQueue, value)
  const field = find(state.users.fields, (f) => f.name === name)
  if (!inQueue && field && field.field_type === 'radio') {
    dispatch({
      type: ActionTypes.UPDATE_USER_FIELD,
      payload: { name, undefined },
    })
  }
  if (inQueue) {
    dispatch({type: ActionTypes.REMOVE_WAITING_LIST_QUEUE, payload: value})
  } else {
    dispatch({type: ActionTypes.ADD_WAITING_LIST_QUEUE, payload: value})
    if (field && field.field_type === 'radio') {
      const rest = (field.values || []).map(v => v.OptionValueID).filter(v => v !== value)
      each(rest, v => dispatch({type: ActionTypes.REMOVE_WAITING_LIST_QUEUE, payload: v}))
    }
  }
}

export const createOpenAction = (open) => (dispatch, getState) => {
  if (open) {
    const state = getState()
    const values = state.users.fields.reduce((acc, field) => {
      acc[field.name] = ''
      return acc
    }, {})
    dispatch({
      type: ActionTypes.CREATE_USER_INIT,
      payload: { id: '', values },
    })
  }
  dispatch({ type: ActionTypes.CREATE_USER_OPEN, payload: open })
}

export const updateOpenAction = (open, user) => (dispatch) => {
  if (open) {
    dispatch({ type: ActionTypes.UPDATE_USER_INIT, payload: user })
  }
  dispatch({ type: ActionTypes.UPDATE_USER_OPEN, payload: open })
}

export const validateUser = (user, errorType) => (dispatch, getState) => {
  const state = getState()
  const emptyFields = state.users.fields
    .filter((field) => {
      return field.required && !user.values[field.name]
    })
    .map((field) => field.label)
  if (emptyFields.length > 0) {
    dispatch({
      type: errorType,
      payload: `Attention, un ou plusieurs champs sont vides : ${emptyFields.join(
        ', '
      )}`,
    })
    return false
  }
  return true
}

export const createUser = (user) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.CREATE_USER_REQUEST })
  if (dispatch(validateUser(user, ActionTypes.CREATE_USER_ERROR))) {
    return UserService.postUser(constants.session.getId(), user.values)
      .then((response) => response.users[0])
      .then((user) => {
        // append user to the the list
        dispatch(createUserInt(user))
        return user.id
      })
      .then((userId) => {
        const state = getState()
        const wlQueue = state.user.wlQueue
        return Promise.all(
          wlQueue.map((optionId) =>
            WaitingListService.queueUser(
              constants.session.getId(),
              optionId,
              userId
            )
          )
        )
      })
      .then(() => {
        // close dialog
        dispatch(createOpenAction(false))
        dispatch({ type: ActionTypes.CREATE_USER_SUCCESS })
        // reload waiting lists
        dispatch(fetchWaitingLists(constants.session.getId()))
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.CREATE_USER_ERROR, payload: error })
        return Promise.reject(error)
      })
  }
}

export const updateUser = (user) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.UPDATE_USER_REQUEST })
  if (dispatch(validateUser(user, ActionTypes.UPDATE_USER_ERROR))) {
    return UserService.putUser(constants.session.getId(), user)
      .then((response) => response.users[0])
      .then((user) => {
        // append user to the the list
        dispatch(updateUserInt(user))
        return user.id
      })
      .then((userId) => {
        const state = getState()
        const wlQueue = state.user.wlQueue
        return Promise.all(
          wlQueue.map((optionId) =>
            WaitingListService.queueUser(
              constants.session.getId(),
              optionId,
              userId
            )
          )
        )
      })
      .then(() => {
        // close dialog
        dispatch(updateOpenAction(false))
        dispatch({type: ActionTypes.UPDATE_USER_SUCCESS});
        // reload waiting lists
        dispatch(fetchWaitingLists(constants.session.getId()))
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.UPDATE_USER_ERROR, payload: error })
        return Promise.reject(error)
      })
  }
}
