import { advancedSearch, simpleSearch } from '../../utils/search'
import { formatUser } from '../../services/user.service'
import types from '../types'

const initialState = {
  users: [],
  selectedUsers: [],
  openEditor: false,
  confirmDelete: false,
  fields: [],
  filteredFields: [],
  filteredUsers: [],
  advancedFilters: [],
  searchQuery: '',
  tableSize: 20,
  loading: false,
  error: null
}

export function filterUsers(
  users,
  query,
  advancedFilters = [],
  searchFields = []
) {
  if (advancedFilters.length > 0) {
    const advanced = advancedSearch(users, advancedFilters, searchFields)
    return advanced
  }
  return simpleSearch(users, query, searchFields)
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USERS_REQUEST:
    case types.DELETE_USER_REQUEST:
    case types.RELOAD_USERS_REQUEST:
      return { ...state, error: null, loading: true }

    case types.FETCH_USERS_ERROR:
    case types.DELETE_USER_ERROR:
    case types.RELOAD_USERS_ERROR:
      return { ...state, error: action.payload, loading: false }

    case types.FETCH_USERS_SUCCESS: {
      const { users, fields } = action.payload
      return {
        ...state,
        users,
        fields,
        loading: false,
        filteredFields: fields,
        filteredUsers: filterUsers(
          users,
          state.searchQuery,
          state.advancedFilters,
          state.filteredFields
        )
      }
    }

    case types.RELOAD_USERS_SUCCESS: {
      const { users, fields } = action.payload
      const updatedUsers = state.users.reduce((acc, val) => {
        let user = users.find(f => f.id === val.id) || {}
        return [...acc, Object.assign(val, user)]
      }, [])
      const updatedFields = state.fields.reduce((acc, val) => {
        let field = fields.find(f => f.name === val.name) || {}
        return [...acc, Object.assign(val, field)]
      }, [])
      return {
        ...state,
        users: updatedUsers,
        fields: updatedFields,
        filteredFields: updatedFields,
        filteredUsers: filterUsers(
          updatedUsers,
          state.searchQuery,
          state.advancedFilters,
          state.filteredFields
        )
      }
    }

    case types.DELETE_USER_SUCCESS: {
      const users = state.users.filter(user => user.id !== action.payload.id)
      return {
        ...state,
        users,
        loading: false,
        selectedUsers: [],
        filteredUsers: filterUsers(
          users,
          state.searchQuery,
          state.advancedFilters,
          state.filteredFields
        )
      }
    }

    case types.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
        filteredUsers: filterUsers(
          state.users,
          action.payload,
          state.advancedFilters,
          state.filteredFields
        )
      }

    case types.SET_ADVANCED_SEARCH_QUERY:
      return {
        ...state,
        advancedFilters: action.payload,
        filteredUsers: filterUsers(
          state.users,
          state.searchQuery,
          action.payload,
          state.filteredFields
        )
      }

    case types.SET_FILTERED_USERS: {
      return { ...state, filteredUsers: action.payload }
    }

    case types.SET_SELECTED_USERS:
      return { ...state, selectedUsers: action.payload }

    case types.SET_FILTERED_FIELDS:
      return { ...state, filteredFields: action.payload }

    case types.CONFIRM_USER_DELETE: 
      return { ...state, confirmDelete: !!action.payload }

    case types.CREATE_USER_INT: {
      const users = [ ...state.users, formatUser(state.fields, action.payload)]
      return {
        ...state,
        users,
        filteredUsers: filterUsers(
          users,
          state.searchQuery,
          state.advancedFilters,
          state.filteredFields
        )
      }
    }

    case types.UPDATE_USER_INT: {
      const users = state.users.map(user =>
        user.id === action.payload.id
          ? formatUser(state.fields, action.payload)
          : user
      )
      return {
        ...state,
        error: null,
        loading: false,
        users,
        filteredUsers: filterUsers(
          users,
          state.searchQuery,
          state.advancedFilters,
          state.filteredFields
        )
      }      
    }

    default:
      return state
  }
}
