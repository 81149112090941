import { setNotification } from './notifs'
import UserService from '../../services/user.service'
import ActionTypes from '../types'
import constants from '../../constants'

const FetchUsersRequest = () => ({
  type: ActionTypes.FETCH_USERS_REQUEST,
  payload: null
})

const FetchUsersError = error => ({
  type: ActionTypes.FETCH_USERS_ERROR,
  payload: error
})

const FetchUsersSuccess = payload => ({
  type: ActionTypes.FETCH_USERS_SUCCESS,
  payload
})

export const fetchUsers = session => dispatch => {
  dispatch(FetchUsersRequest())
  UserService.fetchUsers(session)
    .then(response => dispatch(FetchUsersSuccess(response)))
    .catch(error => dispatch(FetchUsersError(error)))
}

const DeleteUserRequest = () => ({
  type: ActionTypes.DELETE_USER_REQUEST,
  payload: null
})

const DeleteUserError = error => ({
  type: ActionTypes.DELETE_USER_ERROR,
  payload: error
})

const DeleteUserSuccess = payload => ({
  type: ActionTypes.DELETE_USER_SUCCESS,
  payload
})

export const deleteUser = user => dispatch => {
  dispatch(DeleteUserRequest())
  UserService.delUser(constants.session.getId(), user)
    .then(() => dispatch(DeleteUserSuccess(user)))
    .catch(error => dispatch(DeleteUserError(error)))
}

const ReloadUsersRequest = () => ({
  type: ActionTypes.RELOAD_USERS_REQUEST,
  payload: null
})

const ReloadUsersError = error => ({
  type: ActionTypes.RELOAD_USERS_ERROR,
  payload: error
})

const ReloadUsersSuccess = payload => ({
  type: ActionTypes.RELOAD_USERS_SUCCESS,
  payload
})

export const reloadUsers = (session, datetime) => dispatch => {
  dispatch(ReloadUsersRequest())
  UserService.checkUsersUpdate(session, datetime)
    .then(response => {
      const users = response.users
      if (users.length > 0) {
        const message =
          users.length === 1
            ? `L'utilisateur ${users[0].values.name} a été mis à jour !`
            : `Les utilisateurs ${users
                .map(user => user.values.name)
                .join(', ')} ont été mis à jour !`
        dispatch(
          setNotification({
            type: 'warning',
            message
          })
        )
      }
      dispatch(ReloadUsersSuccess(response))
    })
    .catch(error => dispatch(ReloadUsersError(error)))
}

export const setSelectedUsers = users => ({
  type: ActionTypes.SET_SELECTED_USERS,
  payload: users
})

export const setFilteredUsers = users => ({
  type: ActionTypes.SET_FILTERED_USERS,
  payload: users
})

export const setSearchQuery = query => ({
  type: ActionTypes.SET_SEARCH_QUERY,
  payload: query
})
export const setFilteredFields = fields => ({
  type: ActionTypes.SET_FILTERED_FIELDS,
  payload: fields
})

export const setAdvancedSearchQuery = queries => ({
  type: ActionTypes.SET_ADVANCED_SEARCH_QUERY,
  payload: queries
})

export const confirmDelete = open => ({
  type: ActionTypes.CONFIRM_USER_DELETE,
  payload: open
})


export const createUserInt = user => dispatch => {
  dispatch({type: ActionTypes.CREATE_USER_INT, payload: user})
}

export const updateUserInt = user => dispatch => {
  dispatch({type: ActionTypes.UPDATE_USER_INT, payload: user})
}
