import React from 'react'
import Typography from '@material-ui/core/Typography'

import ModalButtonWrap from './modalButton'

import {
  BaseForm,
  ConfirmButton,
  CancelButton,
  BaseFormActions
} from '../styled'

function ConfirmForm({ onConfirm, content, title, onBlur }) {
  return (
    <BaseForm>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <br />
      {content}
      <BaseFormActions>
        <CancelButton onClick={onBlur}>ANNULER</CancelButton>
        <ConfirmButton onClick={onConfirm}>VALIDER</ConfirmButton>
      </BaseFormActions>
    </BaseForm>
  )
}

export default function({
  children,
  open,
  onBlur,
  title,
  content,
  onConfirm,
  right = false
}) {
  return (
    <ModalButtonWrap
      open={open}
      onBlur={onBlur}
      right={right}
      content={
        <ConfirmForm
          onConfirm={onConfirm}
          content={content}
          title={title}
          onBlur={onBlur}
        />
      }
    >
      {children}
    </ModalButtonWrap>
  )
}
