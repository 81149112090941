import types from '../types'
import { filter, union } from 'lodash'

const defaultUser = {
  id: '',
  values: {},
}
const defaultWLQueue = []

const initialState = {
  user: defaultUser,
  wlQueue: defaultWLQueue,
  loading: false,
  error: null,
  createOpen: false,
  updateOpen: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_USER_INIT:
    case types.UPDATE_USER_INIT:
      return { ...state, user: { ...action.payload }, wlQueue: defaultWLQueue }

    case types.CREATE_USER_REQUEST:
    case types.UPDATE_USER_REQUEST:
      return { ...state, error: null, loading: true }

    case types.CREATE_USER_ERROR:
    case types.UPDATE_USER_ERROR:
      return { ...state, error: action.payload, loading: false }

    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: defaultUser,
        wlQueue: defaultWLQueue,
        error: null,
        loading: false,
      }

    case types.UPDATE_USER_FIELD: {
      const { name, value } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          values: { ...state.user.values, [name]: value },
        },
      }
    }

    case types.ADD_WAITING_LIST_QUEUE:
      return { ...state, wlQueue: [...union(state.wlQueue, [action.payload])] }

    case types.REMOVE_WAITING_LIST_QUEUE:
      return {
        ...state,
        wlQueue: [...filter(state.wlQueue, (v) => v !== action.payload)],
      }

    case types.CREATE_USER_OPEN:
      return { ...state, createOpen: !!action.payload }

    case types.UPDATE_USER_OPEN:
      return { ...state, updateOpen: !!action.payload }

    default:
      return state
  }
}
