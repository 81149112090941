import axios from 'axios'
import constants from '../constants'
import HttpService from './http.service'
import { filter, omit, isArray } from 'lodash';

const handleResponse = response => {
  if (response.data.error || response.data.error_type) {
    if (typeof response.data.error === 'string') {
      return Promise.reject({ message: response.data.error });
    } else {
      return Promise.reject({ message: response.data.error.message });
    }
  }
  if (response.data.error_type) {
    if (response.data.error_type === 'validation') {
      return Promise.reject({ message: 'Validation error' });
    }
    return Promise.reject({ message: 'Unknown error' });
  }
  return response.data;
}

// add password confirmation field
const appendPasswordConfirm = values => {
  return values.Passwd !== undefined ? {...values, Passwd1: values.Passwd} : values;
}

const fromEurostudioField = field => {
  const { values } = field;
  // for unknown reasons if option only one, values return as object, not an array with one element..
  if ((field.field_type === 'radio' || field.field_type === 'select' || field.field_type === 'multiple') && values && !isArray(values)) {
    return { ...field, values: [values]}
  }
  return field;
}

const toEurostudioUser = (values) => {
  return {
    user: {
      values: appendPasswordConfirm(omit(values, 'id'))
    },
    LanguageID: 1
  }
}

export function formatUser(fields, user) {
  // Add fake field to keep in user values ShortUrl property
  const fieldsEx = [ ...fields, {
    name: 'ShortUrl',
  } ];
  const values = fieldsEx.reduce((acc, field) => {
    const userField = user.values.find(value => {
      // for some reason, both field and labels are mixed in the user values
      return value.name === field.label || value.name === field.name
    }) || { value: '' }
    const value = userField.value;
    return {
      ...acc,
      [field.name]: value
    }
  }, {})
  return {
    ...user,
    values: values
  }
}
export function getLabelByName(fields, name) {
  return fields.find(field => field.name === name).label
}
export function getNameByLabel(fields, label) {
  return fields.find(field => field.label === label).name
}

class UserService extends HttpService {
  fetchUsers(sessionId) {
    return axios
      .get(
        // `${constants.API.URL}/sessions/${sessionId}/users?page=1&limit=100&lang=${constants.language}`,
        `${constants.API.URL}/sessions/${sessionId}/users?&lang=${constants.language}`,
        this.getOptions()
      )
      .then(response => {
        const formatedUsers = filter(response.data.users, u => u.id).map(user =>
          formatUser(response.data.fields, user)
        )
        return {
          ...response.data,
          fields: response.data.fields.map(f => fromEurostudioField(f)),
          users: formatedUsers
        }
      })
      .catch(this.onError)
  }

  postUser(sessionId, user) {
    return axios
      .post(
        `${constants.API.URL}/sessions/${sessionId}/users`,
        toEurostudioUser(user),
        this.getOptions()
      )
      .then(handleResponse)
      .catch(this.onError)
  }

  putUser(sessionId, user, fields) {
    return axios
      .put(
        `${constants.API.URL}/sessions/${sessionId}/users/${user.id}`,
        { user: {...user, values: appendPasswordConfirm(user.values)}},
        this.getOptions()
      )
      .then(handleResponse)
      .catch(this.onError)
  }

  delUser(sessionId, user) {
    return axios
      .delete(
        `${constants.API.URL}/sessions/${sessionId}/users/${user.id}`,
        this.getOptions()
      )
      .then(response => response.data)
      .catch(this.onError)
  }

  checkUsersUpdate(sessionId, dt) {
    return axios
      .get(
        `${constants.API.URL}/sessions/${sessionId}/users/updates?since=${dt}`,
        this.getOptions()
      )
      .then(response => response.data)
      .catch(this.onError)
  }

  createFromCSV(sessionId, csv) {
    const form = new FormData();
    form.append('csv', csv, csv.originalname);
    return axios
      .post(
        `${constants.API.URL}/sessions/${sessionId}/users/csv`,
        form,
        this.getOptions({headers: {'Content-Type': 'multipart/form-data'}})
      )
      .then(handleResponse)
      .catch(this.onError)
  }

  postImage(sessionId, image) {
    const form = new FormData()
    form.append('image', image)

    return axios
      .post(
        `${constants.API.URL}/sessions/${sessionId}/users/image`,
        form,
        this.getOptions()
      )
      .then(response => response.data)
      .catch(this.onError)
  }

  exportToCsv(payload) {
    return axios
      .post(
        `${constants.API.EXCEL_SERVICE}/generate?filename=users&type=buffer`,
        JSON.stringify(payload),
        this.getOptions({ responseType: 'blob', headers: {'Content-Type': 'application/json'} })
      )
      .then(response => response.data)
      .catch(this.onError)
  }
}

export default new UserService()
