import { each, get, includes, keys, reduce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUserContext } from './userContext'
import { toggleWaitingListQueue } from '../store/actions/user'

export function useTyping(input, delay = 1000) {
  const [value, setValue] = useState(input)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(input)
    }, delay)
    return () => clearTimeout(timeout)
  }, [delay, input])
  return value
}

export const useWaitingListOptions = () => {
  const dispatch = useDispatch()
  const user = useUserContext()
  const wlCache = useSelector((state) => state.waitinglist.cache)
  const wlQueue = useSelector((state) => state.user.wlQueue)
  const users = useSelector((state) => state.users.users)
  const fields = useSelector((state) => state.users.fields)
  const optionToField = useMemo(() => {
    return reduce(
      fields,
      (acc, f) => {
        each(f.values || [], (v) => (acc[v.OptionValueID] = f))
        return acc
      },
      {}
    )
  }, [fields])
  const idToUser = useMemo(() => {
    return reduce(
      users,
      (acc, u) => {
        if (u.id) {
          acc[u.id] = u
        }
        return acc
      },
      {}
    )
  }, [users])
  if (!user) {
    return {
      getRest: () => undefined,
      isWaitingListAllowed: () => false,
      isInWaitingList: () => false,
      isInWaitingListQueue: () => false,
      isOptionOwner: () => true,
      toggleWaitingListQueueOption: () => {},
    }
  }
  const getRest = (optionId) => {
    return get(wlCache, `${optionId}.rest`)
  }
  const isInWaitingList = (optionId) => {
    const userId = get(user, 'id')
    if (userId) {
      const users = get(wlCache, `${optionId}.users`)
      return includes(users, userId)
    }
    return false
  }
  const isInWaitingListQueue = (optionId) => {
    return includes(wlQueue, optionId)
  }
  const isOptionOwner = (optionId) => {
    const userId = get(user, 'id')
    if (userId) {
      const field = optionToField[optionId]
      if (field) {
        if (field.field_type === 'multiple') {
          return includes(get(idToUser[userId], `values.${field.name}`), optionId)
        }
        return get(idToUser[userId], `values.${field.name}`) === optionId
      }
    }
    return false
  }
  const toggleWaitingListQueueOption = (optionId) => {
    const field = optionToField[optionId]
    if (field) {
      dispatch(toggleWaitingListQueue(field.name, optionId))
    }
  }
  const isWaitingListAllowed = optionId => {
    return get(wlCache, `${optionId}.waitlistAllowed`) === 'Yes'
  }
  return {
    getRest,
    isWaitingListAllowed,
    isInWaitingList,
    isInWaitingListQueue,
    isOptionOwner,
    toggleWaitingListQueueOption,
  }
}

export const useWaitingListListOptions = () => {
  const user = useUserContext()
  const wlCache = useSelector((state) => state.waitinglist.cache)
  const questioNameToUsers = useMemo(() => {
    return reduce(
      keys(wlCache),
      (acc, key) => {
        const { questionDataAttrLabel, users } = wlCache[key]
        return {
          ...acc,
          [questionDataAttrLabel]: [
            ...(acc[questionDataAttrLabel] || []),
            ...users,
          ],
        }
      },
      {}
    )
  }, [wlCache])
  const isInWaitingList = (fieldName) => {
    const userId = get(user, 'id')
    if (userId) {
      const users = questioNameToUsers[fieldName]
      return includes(users, userId)
    }
    return false
  }
  return {
    isInWaitingList,
  }
}
