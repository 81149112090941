import { CellWrapper, HighlightWrapper } from '../styled'

import {
  CheckboxInput,
  DateInput,
  DefaultInput,
  EmailInput,
  ImageInput,
  MultipleInput,
  PhoneInput,
  RadioInput,
  SelectInput,
  TextInput,
  VisibilityInput
} from './inputTypes'
import React, { useState } from 'react'

import Avatar from '@material-ui/core/Avatar'
import { HighlightText } from './highlight'
import { InputTypes } from './inputTypes'
import { SearchableInputs } from '../search'
import { splitPhoneNumber } from '../../utils/phone'
import { useWaitingListListOptions } from '../../utils/hooks';

export * from './inputTypes'

export function GuessInputType({
  setInputRef = () => {},
  type,
  editable = true,
  value,
  onChange,
  checked = false,
  values = []
}) {
  switch (type) {
    case InputTypes.textarea:
    case InputTypes.text: {
      return (
        <TextInput
          setInputRef={setInputRef}
          editable={editable}
          textarea={type === 'textarea'}
          value={value}
          onChange={onChange}
        />
      )
    }
    case InputTypes.image: {
      return (
        <ImageInput
          setInputRef={setInputRef}
          editable={editable}
          checked={checked}
          value={value}
          onChange={onChange}
        />
      )
    }
    case InputTypes.checkbox: {
      return (
        <CheckboxInput
          setInputRef={setInputRef}
          editable={editable}
          checked={checked}
          value={value}
          onChange={onChange}
        />
      )
    }
    case InputTypes.visibility: {
      return (
        <VisibilityInput
          setInputRef={setInputRef}
          editable={editable}
          value={value}
          onChange={onChange}
        />
      )
    }
    case InputTypes.multipleSelect: {
      return (
        <MultipleInput
          editable={editable}
          value={value}
          onChange={onChange}
          values={values}
        />
      )
    }
    case InputTypes.radio: {
      return (
        <RadioInput
          setInputRef={setInputRef}
          editable={editable}
          value={value}
          values={values}
          onChange={onChange}
        />
      )
    }
    case InputTypes.phone: {
      return (
        <PhoneInput
          setInputRef={setInputRef}
          editable={editable}
          value={value}
          onChange={onChange}
        />
      )
    }
    case InputTypes.email: {
      return (
        <EmailInput
          setInputRef={setInputRef}
          editable={editable}
          value={value}
          onChange={onChange}
        />
      )
    }
    case InputTypes.select: {
      return (
        <SelectInput
          setInputRef={setInputRef}
          editable={editable}
          value={value || ""}
          onChange={onChange}
          values={values}
        />
      )
    }
    case InputTypes.date: {
      return (
        <DateInput
          setInputRef={setInputRef}
          editable={editable}
          value={value}
          onChange={onChange}
        />
      )
    }
    default: {
      return (
        <DefaultInput
          setInputRef={setInputRef}
          editable={editable}
          value={value}
          onChange={onChange}
        />
      )
    }
  }
}

export function CellResolver({ cell, onChange, value, values = [] }) {
  const {isInWaitingList} = useWaitingListListOptions()
  const [inputRef, setInputRef] = useState(null)
  const [lastClick, setLastClick] = useState(0)
  const [editable, setEditable] = useState(false)
  const inWaitinglist = isInWaitingList(cell.name)
  // const resolveHighlightValue = (type, value, values) => {
  //   if (type === InputTypes.select || type === InputTypes.radio) {
  //     return (find(values, v => v.OptionValueID === value) || {}).Value
  //   }
  //   return value;
  // }
  const onClick = () => {
    const time = new Date().getTime()
    if (time - lastClick < 500) {
      setEditable(true)
      if (inputRef && inputRef.focus) {
        setImmediate(() => {
          inputRef.focus()
        })
      }
    }
    setLastClick(time)
  }
  const onBlur = () => {
    setEditable(false);
  }
  const showHighlight = SearchableInputs.indexOf(cell.field_type) > -1
  const showMaskedPhone = cell.field_type === InputTypes.phone
  const showImage = cell.field_type === InputTypes.image
  return (
    <CellWrapper className="wrapper" onClick={onClick} onBlur={onBlur}>
      {inWaitinglist ? (
        <div style={{ color: 'red', fontWeight: 'bold', fontSize: 'small' }}>On waiting list</div>
      ) :
      editable === true ||
      (showHighlight === false &&
        showMaskedPhone === false &&
        showImage === false) ? (
        <GuessInputType
          style={{ color: 'transparent !important' }}
          setInputRef={setInputRef}
          type={cell.field_type}
          editable={editable && cell.name !== 'Email'}
          value={value}
          values={values}
          onChange={onChange}
        />
      ) : showHighlight ? (
        <HighlightText field={cell} value={value} />
      ) : showMaskedPhone ? (
        <HighlightWrapper>{splitPhoneNumber(value)}</HighlightWrapper>
      ) : showImage ? (
        <Avatar src={value} />
      ) : null}
    </CellWrapper>
  )
}
