import { Typography } from '@material-ui/core'
import styled from 'styled-components'

import constants from '../../constants'

const { grey, lightGrey } = constants.colors

export const FileLink = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`

export const FileInputLabel = styled.label`
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid ${lightGrey};
  padding: 8px;
  border-radius: 4px;
  font-family: Roboto;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${grey};
  }
`

export const BaseFieldLabel = styled.label`
  margin-right: 20px;
`

export const Compulsory = styled.sup`
  color: tomato;
`
