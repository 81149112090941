import axios from 'axios'

import constants from '../constants'

import HttpService from './http.service'

class AuthService extends HttpService {
  autologin(data) {
    return axios
      .post(`${constants.API.AUTH}/autologin`, data, {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: data.token
        }
      })
      .then(response => {
        return {
          user: response.data,
          token:
            response.headers['Authorization'] ||
            response.headers['authorization']
        }
      })
      .catch(this.onError)
  }
}

export default new AuthService()
