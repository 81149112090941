export default {
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',

  RELOAD_USERS_REQUEST: 'RELOAD_USERS_REQUEST',
  RELOAD_USERS_ERROR: 'RELOAD_USERS_ERROR',
  RELOAD_USERS_SUCCESS: 'RELOAD_USERS_SUCCESS',

  SET_SELECTED_USERS: 'SET_SELECTED_USERS',
  SET_FILTERED_USERS: 'SET_FILTERED_USERS',
  SET_FILTERED_FIELDS: 'SET_FILTERED_FIELDS',

  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_ADVANCED_SEARCH_QUERY: 'SET_ADVANCED_SEARCH_QUERY',

  CONFIRM_USER_DELETE: 'CONFIRM_USER_DELETE',

  CREATE_USER_INT: 'CREATE_USER_INT',
  UPDATE_USER_INT: 'UPDATE_USER_INT'

}
