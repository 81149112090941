import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  RadioGroup,
  Radio,
  TextField
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import EyeIcon from '@material-ui/icons/Visibility'
import React, { useRef, useState, useEffect } from 'react'

import UserService from '../../services/user.service'
import constants from '../../constants'
import { isArray, isEmpty, find, reduce, filter } from 'lodash'

import {
  CustomInput,
  InputWrapper,
  EditImageIcon,
  CustomCellWrapper,
  DeleteImageIcon
} from '../styled'
import { useWaitingListOptions } from '../../utils/hooks'
import { WaitingListLabel } from './waitingListLabel'

const { greySecond, greyBlack, grey } = constants.colors

export const InputTypes = {
  textarea: 'textarea',
  text: 'text',
  checkbox: 'checkbox',
  multipleSelect: 'multiple',
  radio: 'radio',
  email: 'email',
  select: 'select',
  date: 'date',
  visibility: 'visibility',
  phone: 'phone',
  image: 'image'
}

export function ImageInput({ setInputRef, value, onChange, editable = true }) {
  const [displayedImage, setDisplayedImage] = useState(value)
  const ref = useRef(null)
  const deleteImage = () => {
    onChange({ target: { value: null } })
  }
  const onEdit = () => {
    ref.current.click()
  }
  const onFileChange = () => {
    const file = ref.current.files[0]
    // tmp: display image as b64 while uploading
    const reader = new FileReader()
    reader.onloadend = function() {
      setDisplayedImage(reader.result)
    }
    reader.readAsDataURL(file)
    UserService.postImage(file).then(({ path }) => {
      setDisplayedImage(path)
      onChange({ target: { value: path } })
    })
  }
  return (
    <InputWrapper>
      <input
        onChange={onFileChange}
        ref={ref}
        type="file"
        style={{ display: 'none' }}
      />
      <Avatar src={displayedImage} />
      <EditImageIcon onClick={onEdit} style={{ fontSize: 16 }} />
      <DeleteImageIcon onClick={deleteImage} style={{ fontSize: 16 }} />
    </InputWrapper>
  )
}

export function PhoneInput({ setInputRef, value, onChange, editable = true }) {
  return (
    <CustomInput
      inputRef={ref => setInputRef(ref)}
      disabled={!editable}
      onChange={onChange}
      onBlur={() => onChange({ target: { value } })}
      value={value}
    />
  )
}

export function VisibilityInput({ value, onChange }) {
  const toggle = event => {
    // as we are not using an input,
    // we have to manually set the value into the event
    // so this input can behave like the other inputs.
    event.target = { value: !value }
    onChange(event, true)
  }
  return (
    <IconButton onClick={toggle}>
      <EyeIcon
        style={{
          color: value === true ? greyBlack : greySecond
        }}
      />
    </IconButton>
  )
}

export function TextInput({
  setInputRef,
  value,
  onChange,
  editable = true,
  textarea = false
}) {
  return (
    <CustomCellWrapper>
      <CustomInput
        inputRef={ref => setInputRef(ref)}
        disabled={!editable}
        multiline={textarea}
        style={{ width: '100%' }}
        onChange={onChange}
        onBlur={() => onChange({ target: { value } })}
        type="text"
        value={value}
      />
    </CustomCellWrapper>
  )
}

export function RadioInput({ setInputRef, value, values, onChange, editable = true }) {
  const {
    isWaitingListAllowed,
    getRest,
    isInWaitingList,
    isInWaitingListQueue,
    isOptionOwner,
    toggleWaitingListQueueOption
  } = useWaitingListOptions()
  return (
    <CustomCellWrapper>
      <RadioGroup value={value} onBlur={() => onChange({ target: { value } })} onChange={onChange}>
        { values.map(v => (<FormControlLabel
          key={v.OptionValueID}
          value={v.OptionValueID}
          label={<WaitingListLabel
            label={v.Value} 
            rest={getRest(v.OptionValueID)}
            isWaitingListAllowed={isWaitingListAllowed(v.OptionValueID)}
            isOptionOwner={isOptionOwner(v.OptionValueID)}
            inWaitingList={isInWaitingList(v.OptionValueID)}
            inWaitingListQueue={isInWaitingListQueue(v.OptionValueID)}
            onToggleWaitingListQueue={() => toggleWaitingListQueueOption(v.OptionValueID)}
          />} 
          control={<Radio
            inputRef={ref => setInputRef(ref)}
            disabled={!editable || !(getRest(v.OptionValueID) !== 0 || isOptionOwner(v.OptionValueID))} />
        }
      />))}
      </RadioGroup>
    </CustomCellWrapper>
  )
}

export function CheckboxInput({
  setInputRef,
  value,
  onChange,
  checked = false,
  editable = true
}) {
  if (value === 'N' || value === 'Y') {
    checked = value === 'Y';
  }
  return (
    <CustomCellWrapper>
      <Checkbox
        inputRef={ref => setInputRef(ref)}
        checked={checked}
        disabled={!editable}
        style={{ width: '100%' }}
        onChange={onChange}
        // onBlur={() => onChange({ target: { value } })}
        value={value}
      />
    </CustomCellWrapper>
  )
}

export function MultipleInput({ value, values, onChange, editable = true }) {
  const {
    getRest,
    isWaitingListAllowed,
    isInWaitingList,
    isInWaitingListQueue,
    isOptionOwner,
    toggleWaitingListQueueOption
  } = useWaitingListOptions()
  return (
    <CustomCellWrapper>
      {values.map(v => (
        <FormControlLabel
          key={v.OptionValueID}
          control={
            <Checkbox
              disabled={!editable || !(getRest(v.OptionValueID) !== 0 || isOptionOwner(v.OptionValueID)) || isInWaitingList(v.OptionValueID)}
              checked={value.indexOf(v.OptionValueID) > -1}
              onChange={ev => {
                const update = ev.target.checked ? [...value, v.OptionValueID] : [...filter(value, o => o !== v.OptionValueID)]
                onChange({target:{value: update}})
              }}
              value={v.OptionValueID}
            />
          }
          label={<WaitingListLabel
            label={v.Value} 
            rest={getRest(v.OptionValueID)}
            isWaitingListAllowed={isWaitingListAllowed(v.OptionValueID)}
            isOptionOwner={isOptionOwner(v.OptionValueID)}
            inWaitingList={isInWaitingList(v.OptionValueID)}
            inWaitingListQueue={isInWaitingListQueue(v.OptionValueID)}
            onToggleWaitingListQueue={() => toggleWaitingListQueueOption(v.OptionValueID)}
          />} 
        />
      ))}
    </CustomCellWrapper>
  )
}

export function EmailInput({ setInputRef, value, onChange, editable = true }) {
  return (
    <CustomCellWrapper>
      <CustomInput
        inputRef={ref => setInputRef(ref)}
        disabled={!editable}
        style={{ width: '100%' }}
        onChange={onChange}
        onBlur={() => onChange({ target: { value } })}
        type="email"
        value={value}
      />
    </CustomCellWrapper>
  )
}

export function SelectInput({
  setInputRef,
  value,
  values,
  onChange,
  editable = true
}) {
  return (
    <CustomCellWrapper>
      <TextField
        // inputRef={ref => setInputRef(ref)}
        select
        disabled={!editable}
        value={value}
        onChange={onChange}
        onBlur={() => onChange({ target: { value } })}
        style={{ width: '100%' }}
        SelectProps={{
          MenuProps: {
            style: { width: '100%' }
          }
        }}
        margin="normal"
      >
      <MenuItem style={{ width: '100%' }} value="+" key="+">&nbsp;</MenuItem>
        {values.map(v => (
          <MenuItem style={{ width: '100%' }} value={v.OptionValueID} key={v.OptionValueID}>
            {v.Value}
          </MenuItem>
        ))}
      </TextField>
    </CustomCellWrapper>
  )
}

export function DateInput({ setInputRef, value, onChange, editable = true }) {
  return (
    <CustomInput
      inputRef={ref => setInputRef(ref)}
      disabled={!editable}
      style={{ width: '100%' }}
      onChange={onChange}
      onBlur={() => onChange({ target: { value } })}
      type="date"
      value={value}
    />
  )
}

export function DefaultInput({ value, onChange, editable = true }) {
  const color = editable ? grey : greyBlack
  return <div style={{ color }}>default : {value}</div>
}

export function resolveValue(field, value) {
  if (field.field_type === InputTypes.select || field.field_type === InputTypes.radio) {
    return (find(field.values, v => v.OptionValueID === value) || {}).Value || '';
  } else if (field.field_type === InputTypes.multipleSelect) {
    if (value === null) {
      value = [];
    }
    value = !isArray(value) ? value.split(',') : value;
    const r = reduce(value, (acc, v) => {
      const val = (find(field.values, o => o.OptionValueID === v) || {}).Value;
      return val ? [...acc, val] : acc;
    }, []).join(',');
    return r;

  }
  return isEmpty(value) ? '' : value;
}