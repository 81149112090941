import React from 'react'

import {
  ModalButtonWrap,
  Backdrop,
  Modal,
  Triangle,
  ModalContentWrapper
} from '../styled'

export default function({ children, open, right = false, content, onBlur }) {
  return (
    <>
      <Backdrop onClick={onBlur} hidden={!open} />
      <ModalButtonWrap>
        {children}
        <Modal right={right} hidden={!open}>
          <Triangle />
          <ModalContentWrapper right={right}>{content}</ModalContentWrapper>
        </Modal>
      </ModalButtonWrap>
    </>
  )
}
