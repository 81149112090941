function splitInternationalPhoneNumber(number) {
  return number.replace(
    /(\+\d{1,3})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
    '$1 $2 XX XX $5 $6'
  )
}
function splitClassicPhoneNumber(number) {
  return number.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 XX XX $4 $5')
}

export function splitPhoneNumber(number) {
  const trimmed = number.replace(/ /, '')
  return number.indexOf('+') === -1
    ? splitClassicPhoneNumber(trimmed)
    : splitInternationalPhoneNumber(trimmed)
}
