export default {
  CREATE_USER_INIT: 'CREATE_USER_INIT',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_OPEN: 'CREATE_USER_OPEN',

  UPDATE_USER_INIT: 'UPDATE_USER_INIT',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_OPEN: 'UPDATE_USER_OPEN',

  UPDATE_USER_FIELD: 'UPDATE_USER_FIELD',
  ADD_WAITING_LIST_QUEUE: 'ADD_WAITING_LIST_QUEUE',
  REMOVE_WAITING_LIST_QUEUE: 'REMOVE_WAITING_LIST_QUEUE',
}
