import React from 'react'
import Typography from '@material-ui/core/Typography'
import ModalButtonWrap from '../modal/modalButton'
import { EditButton, BaseForm, BaseFormActions } from '../styled'
import { useDispatch, useSelector } from 'react-redux'
import { UserContext } from '../../utils/userContext'
import {
  updateOpenAction,
  updateUser,
  updateUserField,
} from '../../store/actions/user'
import { UserForm } from './UserForm'

function EditUserForm({
  fields,
  user,
  onUpdateUserField,
  error,
  loading,
  onUpdate,
}) {
  return (
    <BaseForm>
      <Typography variant="h6" gutterBottom>
        {' '}
        Éditer un utilisateur :
      </Typography>
      <br />
      <UserContext.Provider value={user}>
        <UserForm
          fields={fields}
          user={user}
          onUpdateUserField={onUpdateUserField}
          error={error}
          loading={loading}
        />
      </UserContext.Provider>
      <BaseFormActions>
        <EditButton onClick={onUpdate}>ÉDITER</EditButton>
      </BaseFormActions>
    </BaseForm>
  )
}

export default function () {
  const dispatch = useDispatch()
  const fields = useSelector((state) => state.users.fields)
  const selectedUsers = useSelector((state) => state.users.selectedUsers)
  const open = useSelector((state) => state.user.updateOpen)
  const user = useSelector((state) => state.user.user)
  const error = useSelector((state) => state.user.error)
  const loading = useSelector((state) => state.user.loading)
  const setOpen = (open) => {
    dispatch(updateOpenAction(open, selectedUsers[0]))
  }
  const onUpdateUserField = (name, value) => {
    dispatch(updateUserField(name, value))
  }
  const onUpdate = () => {
    dispatch(updateUser(user))
  }
  return (
    <ModalButtonWrap
      open={open}
      onBlur={() => setOpen(false)}
      content={
        fields.length > 0 && selectedUsers.length === 1 ? (
          <UserContext.Provider value={user}>
            <EditUserForm
              fields={fields}
              user={user}
              onUpdateUserField={onUpdateUserField}
              error={error}
              loading={loading}
              onUpdate={onUpdate}
            />
          </UserContext.Provider>
        ) : null
      }
    >
      <EditButton
        disabled={selectedUsers.length !== 1}
        onClick={() => setOpen(true)}
      >
        ÉDITER
      </EditButton>
    </ModalButtonWrap>
  )
}
