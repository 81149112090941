import Progress from '@material-ui/core/CircularProgress'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import styled from 'styled-components'

import constants from '../../constants'

export const CircularProgress = styled(Progress)`
  position: absolute;
`

export const Notification = styled(SnackbarContent)`
  background-color: ${props => constants.colors[props.type]};
  display: 'flex';
  align-items: 'center';
  justify-content: center;
`
