import React, { useEffect, useState } from 'react'
import { TopBar, TopBarLeft, TopBarRight } from '../styled'
import AddUser from './AddUser'
import DelUsers from './DelUsers'
import EditUser from './EditUser'
import ExportCSV from './ExportCSV'
import FilterFields from './FilterFields'
import ImportCSV from './ImportCSV'
import Search from '../search'
import AddUserIFrame from './AddUserIFrame'
import WaitingList from './WaitingList'

export default function() {
  const [addUserMode, setAddUserMode] = useState('native');
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const addUserModeParam = params.get('add-user-mode') || 'native';
    if (addUserModeParam === 'none' || addUserModeParam === 'iframe') {
      setAddUserMode(addUserModeParam);
    }
  }, []);
  return (
    <TopBar>
      <TopBarLeft>
        { addUserMode === 'native' && <AddUser/> }
        { addUserMode === 'iframe' && <AddUserIFrame/> }
        <EditUser />
        <DelUsers />
        <ImportCSV />
        <ExportCSV />
        <WaitingList />
      </TopBarLeft>
      <TopBarRight>
        <FilterFields />
        <Search />
      </TopBarRight>
    </TopBar>
  )
}
