import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import reducers from './reducers'

const middleWares = [thunk]
if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger')
  const logger = createLogger({ collapsed: true })
  middleWares.push(logger)
}

const store = createStore(reducers, compose(applyMiddleware(...middleWares)))
export default store
