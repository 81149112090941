import EyeIcon from '@material-ui/icons/Visibility'
import React, {useState, useEffect} from 'react'
import Table from 'react-table'
import sort from 'lodash.sortby'
import { getColumnWidth } from '../../utils/table'
import { GuessInputType } from '../inputs'
import { InputTypes } from '../inputs/inputTypes'
import { setFilteredUsers, setSelectedUsers, confirmDelete } from '../../store/actions/users'
import { StyledHeader, StyledEyeIcon, ActionButton } from '../styled'
import cellResolver from './cellResolver'
import constants from '../../constants'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import './customTable.css'
import { useDispatch, useSelector } from 'react-redux'
import { updateOpenAction } from '../../store/actions/user'

const { grey, darkGrey } = constants.colors

const TrStyles = () => {
  return {
    style: {
      borderBottom: `2px solid ${darkGrey}`,
      backgroundColor: grey
    }
  }
}

export default function({ users = [], fields = [], filteredFields = [] }) {
  const tableRef = React.createRef()
  const selectedUsers = useSelector(state => state.users.selectedUsers)
  const dispatch = useDispatch()
  const onSelectedUser = selectedUser => (_event, checked) => {
    if (checked) dispatch(setSelectedUsers([...selectedUsers, selectedUser]))
    else
      dispatch(
        setSelectedUsers(
          selectedUsers.filter(user => user.id !== selectedUser.id)
        )
      )
  }
  const editUser = selectedUser => () => {
    dispatch(setSelectedUsers([selectedUser]))
    dispatch(updateOpenAction(true, selectedUser))
  }
  const deleteUser = selectedUser => () => {
    dispatch(setSelectedUsers([selectedUser]))
    dispatch(confirmDelete(true))
  }
  const [staticColumns, setStaticColumns] = useState([])
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [actionColumns, setActionColumns] = useState([])
  const [columns, setColumns] = useState([])

  const onChange = () => {
    if (tableRef.current) {
      const data = tableRef.current
        .getResolvedState()
        .sortedData.map(val => val._original)
        
      dispatch(setFilteredUsers(data))
    }
  }

  useEffect(()=>{
    setStaticColumns(
      [
        {
          Header: '',
          accessor: 'id',
          resizable: false,
          sortable: true,
          Cell: ({ original }) => (
            <GuessInputType
              checked={!!selectedUsers.find(user => user.id === original.id)}
              value={original.id}
              type="checkbox"
              onChange={onSelectedUser(original)}
            />
          ),
          maxWidth: 60
        },
        {
          Header: <StyledHeader>ID</StyledHeader>,
          accessor: 'id',
          sortable: true,
          resizable: false,
          Cell: ({ index }) => <StyledHeader>{index + 1}</StyledHeader>,
          maxWidth: 60
        }
      ]
    )
  },[selectedUsers])

  useEffect(()=>{
    setDynamicColumns(
      filteredFields.map(field => ({
        Header:
          field.name === InputTypes.visibility ? (
            <StyledEyeIcon>
              <EyeIcon />
            </StyledEyeIcon>
          ) : (
            <StyledHeader>{field.label}</StyledHeader>
          ),
        sortable: true,
        id: field.name,
        accessor: row => row.values[field.name],
        Cell: cellResolver(fields, field),
        width:
          field.name === InputTypes.visibility
            ? 75
            : getColumnWidth(
                users,
                row => row.values[field.name],
                field.label,
                field.field_type
              )
      }))
    )
  },[filteredFields,fields, users])

  useEffect(()=>{
    setActionColumns(
      [
        {
          Header: '',
          accessor: 'id',
          resizable: false,
          sortable: true,
          Cell: ({ original}) => (
            <>
              <ActionButton onClick={ editUser(original) }>
                <EditIcon/>
              </ActionButton>
              <ActionButton onClick={ deleteUser(original) }>
                <DeleteIcon/>
              </ActionButton>
            </>
          ),
          maxWidth: 60
        }
      ]
    )
  }, [])

  useEffect(()=>{
    setColumns(
      users.length === 0
      ? []
      : [
          ...staticColumns,
          ...sort(dynamicColumns, [
            item => (item.id === InputTypes.visibility ? -1 : 1),
            item => fields.findIndex(f => f.name === item.id)
          ]),
          ...actionColumns
        ]
    )

  },[users, dynamicColumns, staticColumns, actionColumns])

  const data = users
  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={data}
      getTheadProps={TrStyles}
      showPagination={true}
      showPaginationTop={true}
      showPaginationBottom={false}
      debug={false}
      sort
      defaultPageSize={10}
      onSortedChange={onChange}
      getTheadThProps={() => ({
        style: {
          border: 'none'
        }
      })}
      getTdProps={(column, row, cell) => {
        return {
          style: {
            borderLeft:
              cell.id === InputTypes.visibility ? '2px solid #757575' : 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
      }}
      getTrGroupProps={() => {
        return {
          style: {
            borderBottom: 'none'
          }
        }
      }}
      onPageChange={onChange}
      style={{
        width: 'calc(100% - 1px)',
        height: '100%'
      }}
      className="-highlight"
      previousText="Précédent"
      nextText="Suivant"
      ofText="sur"
      rowsText="lignes"
      noDataText=""
    />
  )
}
