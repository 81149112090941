import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { GuessInputType } from '../inputs'
import { SearchableInputs } from './index'
import { setAdvancedSearchQuery } from '../../store/actions/users'

import {
  AdvancedSearchButton,
  RemoveSearchButton,
  BaseForm,
  BaseField,
  BaseFieldLabel,
  BaseFieldInput,
  BaseFormActions
} from '../styled'
import { useDispatch, useSelector } from 'react-redux'

export default function({ opened, onBlur }) {
  const dispatch = useDispatch()
  const fields = useSelector(state => state.users.fields)
  const filterFields = fields.filter(
    field => SearchableInputs.indexOf(field.field_type) > -1
  )
  const [filters, setFilters] = useState([])

  const resetFields = () => {
    setFilters(
      filterFields.map(field => ({
        ...field,
        value: ''
      }))
    )
  }

  useEffect(resetFields, [fields])
  const onChange = (field, value) => {
    const newFilters = filters.map(currentField => {
      if (field.name === currentField.name) {
        return {
          ...currentField,
          value
        }
      }
      return currentField
    })
    setFilters(newFilters)
  }

  const performSearch = () => {
    const advancedFilters = filters
      .filter(field => field.value !== '')
      .map(filter => {
        return {
          name: filter.name,
          value: filter.value
        }
      })
    dispatch(setAdvancedSearchQuery(advancedFilters))
  }
  const removeSearch = () => {
    resetFields()
    dispatch(setAdvancedSearchQuery([]))
    onBlur()
  }
  return (
    <BaseForm>
      <Typography variant="h6" gutterBottom>
        Recherche avancée
      </Typography>
      <br />
      {filters.map(field => (
        <BaseField key={field.name}>
          <BaseFieldLabel>
            <Typography variant="body1">{field.label} :</Typography>
          </BaseFieldLabel>
          <BaseFieldInput>
            <GuessInputType
              editable={true}
              onChange={event => onChange(field, event.target.value)}
              type={field.field_type}
              values={field.values || []}
              value={field.value}
            />
          </BaseFieldInput>
        </BaseField>
      ))}
      <BaseFormActions>
        <RemoveSearchButton onClick={removeSearch}>ANNULER</RemoveSearchButton>
        <AdvancedSearchButton onClick={performSearch}>
          RECHERCHER
        </AdvancedSearchButton>
      </BaseFormActions>
    </BaseForm>
  )
}
