export default class HttpService {
  getOptions(options = {}) {
    const token = localStorage.getItem('token') || ''
    const auth = token ? { 'Authorization': `Bearer ${token}`} : {};
    return {
      ...options,
      headers: {...options.headers, ...auth}
    }
  }

  onError(res) {
    console.error(res)
    const message =
      (res.data && res.data.message) ? res.data.message : 
        res.message ? res.message : 'Unknown server error'
    return Promise.reject(message)
  }
}
