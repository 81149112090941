import { Typography } from '@material-ui/core'
import React, { useCallback, useState, useEffect } from 'react'

import { fetchUsers } from '../../store/actions/users'
import UserService from '../../services/user.service'
import { setNotification } from '../../store/actions/notifs'
import ModalButtonWrap from '../modal/modalButton'
import constants from '../../constants'

import {
  BlueButton,
  BaseForm,
  BaseFormErrors,
  FileLink,
  FileInput,
  FileInputLabel,
  FileInputWrapper,
  FileName,
  ImportActions,
  CircularProgress
} from '../styled'
import { useDispatch, useSelector } from 'react-redux'

export default function(props) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(null)
  const [csv, setCsv] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => { 
    if (open) {
      setError(false);
      setName(null);
      setCsv(null);
    }
  }, [open]);
  const dispatch = useDispatch()
  const fields = useSelector(state => state.users.fields)
  const downloadFile = useCallback(() => {
    const csvHeader = fields.map(field => field.name).join(';') + ';'
    const file = new Blob([csvHeader], { type: 'text/csv' })
    // we cannot download the file, but we can create a fake link & click on it
    const fakeLink = document.createElement('a')
    fakeLink.href = URL.createObjectURL(file)
    // set the file name:
    fakeLink.download = `template_import_${constants.session.getId()}.csv`
    fakeLink.click()
  }, [fields])

  const onFileChange = event => {
    const file = event.target.files[0]
    setName(file.name)
    setCsv(file)
  }

  const uploadFile = () => {
    setLoading(true)
    setError(false)
    UserService.createFromCSV(constants.session.getId(), csv)
      .then(created => {
        const { success } = created
        dispatch(fetchUsers(constants.session.getId()));
        dispatch(
          setNotification({
            message: success,
            type: 'success'
          })
        )
        setLoading(false)
        setOpen(false)
      })
      .catch(err => {
        setLoading(false);
        const errors = /<br>/.test(err) ? err.split('<br>') : err;
        setError(errors);
      })
  }

  return (
    <ModalButtonWrap
      onBlur={() => setOpen(false)}
      open={open}
      content={
        <BaseForm>
          <Typography variant="h6" gutterBottom>
            Choisir un fichier à importer
          </Typography>
          <FileInputWrapper>
            <FileInputLabel>
              Choisir un fichier
              <FileInput onChange={onFileChange} type="file" />
            </FileInputLabel>
            <FileName>{name}</FileName>
          </FileInputWrapper>
          <BaseFormErrors error={error} style={{ overflowY: 'scroll', overflowX: 'hidden'}}>
            {Array.isArray(error) ? error.map((e, i) => (<div key={i}>{e}</div>)) : error }
          </BaseFormErrors>
          <ImportActions>
            <FileLink onClick={downloadFile} variant="caption">
              Télécharger le fichier à compléter
            </FileLink>
            <BlueButton disabled={csv === null || loading} onClick={uploadFile}>
              IMPORTER
              {loading && <CircularProgress size={24} />}
            </BlueButton>
          </ImportActions>
        </BaseForm>
      }
    >
      <BlueButton onClick={() => setOpen(true)}>IMPORTER UN CSV</BlueButton>
    </ModalButtonWrap>
  )
}
