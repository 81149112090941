import { InputBase } from '@material-ui/core'
import Input from '@material-ui/core/InputBase'
import styled from 'styled-components'
import TextField from '@material-ui/core/InputBase'

import constants from '../../constants'

const { white, grey, greySecond } = constants.colors

export const TableSizeInput = styled(TextField)`
  width: 60px;
  height: 40px;
  background-color: ${white};
  border-radius: 4px;
  padding: 0px 10px;
  margin-left: 10px;
`

export const FileInput = styled(Input)`
  display: none;
`

export const SearchField = styled(TextField)`
  background-color: ${white};
  width: calc(100% - 20px);
  height: 38px;
  padding: 0px 10px;
  border-radius: ${props =>
    props.showdrawer === 'true' ? '4px 4px 0px 0px' : '4px'};
  border: 1px solid ${grey};
`

export const CustomInput = styled(InputBase)`
  border-bottom: ${props =>
    props.disabled ? 'none' : `1px solid ${greySecond}`};
  > *:first-child {
    cursor: ${props => (!props.editable ? 'text' : null)};
  }
`
